import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./common.css";

const classifiedData = [
  {
    _id: "b1234567890rajmudra",
    name: "Ishwar Bongane",
    email: "ishwarbangone@gmail.com",
    password: "0987654321",
    mobileNumber: "9393199696",
    companyName: "Rajmudra",
    productOrService: "Banana",
    imgSrc: "/assets/banana1.jpeg",
    mainProducts: "Banana",
    altText: "Rajmudra - Banana",
    years: "1 YRS",
    location: "Indapur dist Pune, Maharashtra, India",
    tooltipText: "Rajmudra, Indapur dist Pune, Maharashtra",
    rating: "4.7",
    ratingPercent: "92%",
    ratingsCount: "85",
    responseRate: "88%",
    whatsappConfirmed: true,
  },
  {
    _id: "dipak-vishwas-dhangar-yawal",
    name: "Dipak Vishwas Dhangar",
    email: "abc@gmail.com",
    password: "dipak@123",
    mobileNumber: "7038942812",
    companyName: "Dipak Vishwas Dhangar",
    productOrService: "Banana",
    imgSrc: "/assets/banana2.jpg",
    mainProducts: "Banana",
    altText: "Dipak Vishwas Dhangar - Banana",
    years: "1 YRS",
    location: "Yawal, Maharashtra, India",
    tooltipText: "Dipak Vishwas Dhangar, Banana, Yawal",
    rating: "4.5",
    ratingPercent: "91%",
    ratingsCount: "27",
    responseRate: "89%",
    whatsappConfirmed: true,
  },
  {
    _id: "sri-vinayaga-banana-godown",
    name: "Sekar Manickam",
    email: "sekarmanickam123@gmail.com",
    password: "9443426578",
    mobileNumber: "9443426578",
    companyName: "Sri Vinayaga Banana Godown",
    productOrService: "Banana",
    imgSrc: "/assets/banana3.jpg",
    mainProducts: "Banana",
    altText: "Sri Vinayaga Banana Godown - Banana",
    years: "1 YRS",
    location: "Tiruppur, Tamilnadu, India",
    tooltipText: "Sri Vinayaga Banana Godown, Banana, Tiruppur",
    rating: "4.3",
    ratingPercent: "90%",
    ratingsCount: "25",
    responseRate: "92%",
    whatsappConfirmed: true,
  },
  {
    name: "Rahul",
    email: "abc@gmail.com",
    password: "rahul123",
    mobileNumber: "9324576504",
    companyName: "Sasya Enterprises",
    productOrService: "Banana",
    imgSrc: "/assets/banana6.jpg",
    mainProducts: "Bananas, Fresh Fruits, Organic Produce",
    altText: "Sasya Enterprises - Fresh Bananas",
    years: "1 YRS",
    location: "Navi Mumbai, Maharashtra, India",
    tooltipText: "Sasya Enterprises, Navi Mumbai",
    rating: "4.6",
    ratingPercent: "92%",
    ratingsCount: "80",
    responseRate: "89%",
    whatsappConfirmed: true,
  },
  {
    name: "Rahul",
    email: "abc@gmail.com",
    password: "rahul123",
    mobileNumber: "9324576504",
    companyName: "Sasya Enterprises",
    productOrService: "Banana",
    imgSrc: "/assets/bnaana.jpg",
    mainProducts: "Fresh Bananas, Organic Produce, Fruits",
    altText: "Sasya Enterprises - Fresh Bananas",
    years: "1 YRS",
    location: "Nerul, Maharashtra, India",
    tooltipText: "Sasya Enterprises, Nerul",
    rating: "4.3",
    ratingPercent: "88%",
    ratingsCount: "78",
    responseRate: "85%",
    whatsappConfirmed: true,
  },

  // Add more items if needed
];

const BananaComponent = () => {
  <Helmet>
    <title>fresh organic bananas | fresh yellow banana | globalb2bmart </title>
    <meta
      name="description"
      content=" Shop fresh organic bananas and fresh yellow banana at GlobalB2Bmart. Trusted B2B portal connecting buyers and suppliers for top-quality produce!"
    />
  </Helmet>;
  return (
    <>
      <div className="main-box">
        <aside>
          <div className="flt-box-wrap">
            <div className="flt-box mb-0 flt-head">Filters By</div>
            <div className="flt-box bdrt-0">
              <p className="flt-title">Related Categories</p>
              <div className="flt-content">
                <ul className="flt-list cust-scroll">
                  <li>
                    <Link to="#">Apples</Link>
                  </li>
                  <li>
                    <Link to="#">Oranges</Link>
                  </li>
                  <li>
                    <Link to="#">Bananas</Link>
                  </li>
                  <li>
                    <Link to="#">Grapes</Link>
                  </li>
                  <li>
                    <Link to="#">Mangoes</Link>
                  </li>
                  <li>
                    <Link to="#">Berries</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flt-box">
              <p className="flt-title">By State</p>
              <div className="flt-content">
                <div className="flt-search">
                  <input
                    type="text"
                    name="state_id"
                    placeholder="Search State"
                    id="state-search-input"
                  />
                </div>
                <ul className="flt-list cust-scroll" id="state-lists">
                  <li>
                    <Link to="#">All India</Link>
                  </li>
                  <li>
                    <Link to="#">Gujarat</Link>
                  </li>
                  <li>
                    <Link to="#">Maharashtra</Link>
                  </li>
                  <li>
                    <Link to="#">Karnataka</Link>
                  </li>
                  <li>
                    <Link to="#">Punjab</Link>
                  </li>
                  <li>
                    <Link to="#">Uttar Pradesh</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </aside>
        <main>
          <section className="section">
            <p className="sect-title">Explore by Categories</p>
            <div className="horprd expcatg" id="expcatg">
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/apple1.jpeg"
                        width={55}
                        height={55}
                        alt="Apples"
                      />
                    </figure>
                    <p className="title">Apples</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/oranges.jpeg"
                        width={55}
                        height={55}
                        alt="Oranges"
                      />
                    </figure>
                    <p className="title">Oranges</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/banana.jpeg"
                        width={55}
                        height={55}
                        alt="Bananas"
                      />
                    </figure>
                    <p className="title">Bananas</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/grapes.jpeg"
                        width={55}
                        height={55}
                        alt="Grapes"
                      />
                    </figure>
                    <p className="title">Grapes</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/mango.jpeg"
                        width={55}
                        height={55}
                        alt="Mangoes"
                      />
                    </figure>
                    <p className="title">Mangoes</p>
                  </div>
                </Link>
              </div>
            </div>
          </section>
          <ul className="classfied-wrap">
            {classifiedData.map((item, index) => (
              <li key={index}>
                <div className="classified">
                  <div className="prd-info">
                    <div className="prd-box">
                      <img
                        src={item.imgSrc}
                        alt={item.altText}
                        width={250}
                        height={250}
                      />
                    </div>
                  </div>
                  <div className="cinfo">
                    <div className="cbox">
                      <figure>
                        <span className="cmp-year">{item.years}</span>
                      </figure>
                      <div className="cboxr">
                        <Link to="#" target="_blank">
                          <h4 className="title">{item.companyName}</h4>
                        </Link>
                        <p className="cloc tooltip ellipsis">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi-location"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                          </svg>
                          {item.location}
                          <span className="tooltiptext">
                            {item.tooltipText}
                          </span>
                        </p>
                        <div className="rating-wrap">
                          <span className="rtbox">{item.rating}</span>
                          <span
                            className="crate"
                            style={{ "--_score": item.ratingPercent }}
                          />
                          <span className="rate-text">
                            {item.ratingsCount} Ratings
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="caddit">
                      <div className="item">
                        <div
                          className="ca-box modal-btn"
                          data-modal="motc"
                          data-src={item.trustCertificateUrl}
                        >
                          <p>
                            <i className="l3icon motc-icon" />
                          </p>
                        </div>
                      </div>
                      <div className="item">
                        <div className="ca-box">
                          <p>
                            <i className="l3icon resp-icon" />
                          </p>
                          <p>
                            <span>Response Rate</span>{" "}
                            <b>{item.responseRate}</b>
                          </p>
                        </div>
                        <p>
                          <span>Main Products</span> <b>{item.mainProducts}</b>
                        </p>
                      </div>
                    </div>
                    <div className="caction">
                      <Link to={"/register-buyer"}>
                        <p>Contact Supplier</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </main>
      </div>

      <div class="category-content">
        <h2
          style={{
            color: "orange",
            textAlign: "center",
            margin: "20px 0px",
          }}
        >
          Fresh Yellow Banana | Premium Quality Bananas from GlobalB2BMart
        </h2>
        <h4>Fresh Yellow Banana: Naturally Sweet and Nutritious</h4>
        <p class="section-content">
          Globalb2bmart connects you with the known suppliers of{" "}
          <b>fresh yellow banana.</b> So, you will always receive the
          best-quality products that are up to international standards. The
          bright yellow color, natural sweetness, and high nutritional value of
          bananas make it one of the perfect fruit for consumption in
          households, restaurants, and food processing units. We also get our
          direct purchases from reputed farms so our bananas reach the hands of
          buyers as fresh as they can get. Enjoy bananas, the sweetest dessert,
          or the perfect banana smoothie—the fresh yellow delivers on the front
          of both taste and health.
        </p>

        <p class="section-heading">
          <strong>Apple Banana: A Unique Blend of Taste and Texture</strong>
        </p>
        <p class="section-content">
          The apple banana variety is the right kind of exotic variety. Just for
          anybody wanting to get his hands dirty. These apple bananas are
          smaller in size and firmer in texture with a little tangy taste,
          almost as if they tasted like apples. Apple bananas have always been
          favored in luxurious dishes, pastries, and fruit salads. Easy access
          to verified suppliers who deal in the variety of apple bananas is
          provided through Globalb2bmart. This addition is sure to be excellent
          for both health-conscious consumers and culinary professionals. The
          network of our suppliers will provide the best quality in apple
          bananas that are filled with natural flavor and nutrients to offer to
          the buyers.
        </p>

        <p class="section-heading">
          <strong>
            Our fresh bananas-At Household Favorite for All Markets
          </strong>
        </p>
        <p class="section-content">
          Globalb2bmart is your global source for <b>fresh bananas.</b> This
          company serves both big importers placing large orders and smaller
          retailers placing local, smaller orders. Fresh bananas are among the
          most widely consumed fruits around the world, as they have energizing
          properties and good benefits concerning digestion. Our website
          provides the customers with the freshest available bananas, which are
          chosen, packaged, and transported under the best possible conditions.
          If you require commercial distribution or direct retail in smaller
          quantities, GlobalB2BMart does its best to make the transaction smooth
          and the delivery punctual. We take high care for freshness; therefore,
          every banana maintains its natural taste, aroma, and nutrient content.
        </p>

        <p class="section-heading">
          <strong>
            Fresh Organic Bananas—Healthy Alternative for a Healthy Life
          </strong>
        </p>
        <p class="section-content">
          The best answer for the health-conscious as well as the
          environmentally conscious consumers is <b>fresh organic bananas.</b>{" "}
          Our website connects buyers with certified suppliers of fresh organic
          bananas, which are grown without synthetic chemicals or pesticides. In
          fact, bananas are produced through eco-friendly farm methods that help
          conserve soil health and biodiversity. Fresh organic bananas come full
          of necessary vitamins and minerals, which will provide the best taste
          and quality. Best for people who eat organic food, it has no residue
          and hence is absolutely safe and healthy to be eaten. Globalb2bmart
          offers its clients nothing but the best organic fruits, thus lifting
          up the standards of lifestyles.
        </p>
        <p class="section-heading">
          <strong>Why Buy Bananas from GlobalB2BMart?</strong>
        </p>
        <p class="section-heading">
          <strong>Verified Exporters</strong>
        </p>

        <p class="section-content">
          All the exporters to whom we have a tie up having all the
          authenticated credentials and are reliable to ensure all the products
          supplied through them are valid and of good quality.
        </p>
        <p class="section-heading">
          <strong>No Compromise with Freshness</strong>
        </p>
        <p class="section-content">
          Bananas are directly imp from the plantations to ensure freshness
          along with sweetness.
        </p>
        <p class="section-heading">
          <strong>Global Platform</strong>
        </p>
        <p class="section-content">
          Whether sourced for local use or for global export, we provide
          effortless logistics and shipments.
        </p>
        <p class="section-heading">
          <strong>Competitive Price</strong>
        </p>
        <p class="section-content">
          Direct farm-to-market sourcing allows us to offer the best price
          without compromising on quality.
        </p>
        <p class="section-heading">
          <strong>Sustainable Practices</strong>
        </p>
        <p class="section-content">
          Our fresh organic bananas are sourced from environment friendly farms
          that practice responsible and sustainable agriculture.
        </p>
        <p class="section-heading">
          <strong>
            Packaging and Export Standards for Fresh Yellow Banana
          </strong>
        </p>
        <p class="section-content">
          Fresh yellow bananas are safely transported from GlobalB2BMart.
          High-quality packing material ensures that fruits do not become
          spoiled during shipping. Some popular packaging options include:
        </p>
        <p class="section-content">
          <b> Carton Boxes:</b> Solid and well aerated to prevent spoilage
          <b> Plastic Crates:</b> Designed for bulk movement and long-time
          storage
          <p class="section-content">
            Packaging, especially customized to meet a buyer's specific needs
            with regards to branding or regional niche requirements.
          </p>
        </p>
        <p class="section-heading">
          <strong>Fresh bananas meeting International market demands</strong>
        </p>
        <p class="section-content">
          Bananas remain one of the most demanded fruits among the markets of
          the world. Through GlobalB2BMart, the buyers will get a consistent and
          quality supply. Our suppliers are very tight with agricultural and
          food safety regulations so that every batch meets global export
          standards. Be it fresh yellow bananas, fresh organic bananas, or apple
          bananas for mass distribution, we allow smooth business transactions
          with reliable and timely deliveries.
        </p>
        <p class="section-heading">
          <strong>
            Partner with Globalb2bmart-Your Gateway to Quality Bananas
          </strong>
        </p>
        <p class="section-content">
          Globalb2bmart is the marketplace beyond markets. We are the most
          trusted partner in the agri-supply chain. We help you connect to the
          most authentic supplier through the easiest way, reducing sources of
          delay and quality problems while sourcing fresh bananas. Be a
          wholesaler, be a retailer, be an exporter—whatever your name may be,
          we give you the best possible solution to your banana sourcing needs.
          Join Globalb2bmart today and enjoy smooth trading with assured
          freshness and quality.
        </p>
        <p class="section-heading">
          <strong>About Globalb2bmart</strong>
        </p>
        <p class="section-content">
          Globalb2bmart is the best B2B portal that connects buyers with
          authentic suppliers of the marketplace. We help in providing
          high-quality fresh agricultural products to businesses, such as fresh
          yellow bananas, fresh organic bananas, and apple bananas. It is this
          concern for efficiency, quality, and smooth transactions that makes
          our platform globally preferred for buying and selling. Whether you
          want to import a large quantity directly from remote nations or a
          relaxed distribution house, Globalb2bmart is the dependable partner of
          yours.
        </p>
      </div>
    </>
  );
};

export default BananaComponent;
