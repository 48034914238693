import React from "react";
import { Helmet } from "react-helmet";
import "./commn.css";

const Fashion = () => {
  const products = [
    {
      title: "Beachwear",
      imageSrc: "/assets/app1.jpg",
      links: [
        { name: "Swimsuits", url: "#" },
        { name: "Cover-ups", url: "#" },
        { name: "Boardshorts", url: "#" },
        { name: "Beach Dresses", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Women's Clothing",
      imageSrc: "/assets/app2.jpg",
      links: [
        { name: "Dresses", url: "#" },
        { name: "Skirts", url: "#" },
        { name: "Blouses", url: "#" },
        { name: "Jumpsuits", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Hand Gloves & Mittens",
      imageSrc: "/assets/app3.jpg",
      links: [
        { name: "Leather Gloves", url: "#" },
        { name: "Winter Mittens", url: "#" },
        { name: "Touchscreen Gloves", url: "#" },
        { name: "Driving Gloves", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Embroidered Apparel & Garments",
      imageSrc: "/assets/app4.jpg",
      links: [
        { name: "Embroidered Jackets", url: "#" },
        { name: "Embroidered Skirts", url: "#" },
        { name: "Embroidered Blouses", url: "#" },
        { name: "Embroidered Shawls", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Fashion Garments",
      imageSrc: "/assets/app5.webp",
      links: [
        { name: "Evening Gowns", url: "#" },
        { name: "Cocktail Dresses", url: "#" },
        { name: "Blazers", url: "#" },
        { name: "Jumpsuits", url: "#" },
        { name: "View More", url: "#" },
      ],
    },

    {
      title: "Industrial Clothing & Work Wear",
      imageSrc: "/assets/app9.jpg",
      links: [
        { name: "Coveralls", url: "#" },
        { name: "High-Visibility Vests", url: "#" },
        { name: "Work Boots", url: "#" },
        { name: "Hard Hats", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Infant & Baby Clothing",
      imageSrc: "/assets/app8.jpg",
      links: [
        { name: "Baby Onesies", url: "#" },
        { name: "Baby T-Shirts", url: "#" },
        { name: "Baby Leggings", url: "#" },
        { name: "Baby Dresses", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Kids Clothing & Dresses",
      imageSrc: "/assets/app10.jpg",
      links: [
        { name: "Kids T-Shirts", url: "#" },
        { name: "Kids Jeans", url: "#" },
        { name: "Kids Dresses", url: "#" },
        { name: "Kids Jackets", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
    {
      title: "Animal Clothing & Accessories",
      imageSrc: "/assets/app12.avif",
      links: [
        { name: "Pet Coats", url: "#" },
        { name: "Horse Blankets", url: "#" },
        { name: "Dog Sweaters", url: "#" },
        { name: "Cat Harnesses", url: "#" },
        { name: "View More", url: "#" },
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Best clothing and apparel suppliers in india | globalb2bmart
        </title>{" "}
        {/* Title Tag */}
        <meta
          name="description"
          content="Globalb2bmart is the best b2b portal in india and is ready to connect you with some big clothing and apparel suppliers in india"
        />{" "}
        {/* Meta Description */}
      </Helmet>

      <div className="pagetitle">
        <h1>
          Your Premier Destination for Apparel & Fashion Industry Connections &
          B2B Trade Hub
        </h1>
      </div>
      <div className="l1-section">
        <div className="container">
          <div className="row l1-box-wrap">
            {products.map((product, index) => (
              <div key={index} className="col-4">
                <div className="l1-box">
                  <h2 className="l1-heading">
                    <a href="#">{product.title}</a>
                  </h2>
                  <div className="d-flex">
                    <div>
                      <figure className="cp" onClick={() => {}}>
                        <img
                          src={product.imageSrc}
                          alt={product.title}
                          width={95}
                          height={95}
                          loading="lazy"
                        />
                      </figure>
                    </div>
                    <div>
                      <ul>
                        {product.links.map((link, linkIndex) => (
                          <li key={linkIndex}>
                            <a href={link.url}>{link.name}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div class="category-content">
        <h2
          style={{
            color: "orange",
            textAlign: "center",
            margin: "20px 0px",
          }}
        >
          Ladies Readymade Garments | Kurti Exporters | GlobalB2BMart
        </h2>
        <p class="section-heading">
          <strong>Ladies Readymade Garments</strong>
        </p>
        <p class="section-content">
          GlobalB2BMart provides you with the best{" "}
          <b> Ladies Readymade Garments</b>
          suppliers. We have an extensive collection of readymade clothing,
          which are stylish and inexpensive, designed for retailers,
          distributors, and fashion designers. Our product range includes
          dresses, tops, sarees, and more that guarantee to tackle customer
          demands. We always ensure that you have the latest trends and
          high-quality garments for customers. Whether you're looking for high
          volume orders or a boutique type of business, our suppliers make sure
          to cover all your demands with premium quality clothing. Every piece
          is built with accuracy as our verified suppliers focus on good
          quality. It is the case for businesses trying to find that variety and
          uniqueness in women's fashion, bringing together ethnic to western
          apparel - all curated differently to fit trends and preferences.
        </p>
        <p class="section-heading">
          <strong>Kurti Exporters</strong>
        </p>
        <p class="section-content">
          Explore a curated selection of <b> Kurti exporters</b> through
          GlobalB2BMart, ensuring access to a variety of traditional and
          contemporary designs. Our exporters provide high-quality kurtis made
          from premium fabrics, blending intricate embroidery with modern cuts
          to appeal to both local and global markets. <b> Kurti exporters</b> at
          GlobalB2BMart specialize in offering kurtis made with diverse
          materials, including cotton, silk, georgette, and rayon. Whether it is
          for mass purchase or custom designs, our reliable exporting network
          ensures the best quality products to be delivered on time. For
          retailers and wholesalers, the long list of casual, festival, and
          formal wear kurtis will definitely help them increase their customer
          reach. Our exporters also ensure that the latest trends and unique
          patterns reach the markets to differentiate business ventures in a
          highly competitive world of fashion.
        </p>
        <p class="section-heading">
          <strong>Clothing Suppliers</strong>
        </p>
        <p class="section-content">
          Partner with the most reputable <b> clothing suppliers</b> in India
          through GlobalB2BMart. Our wide network of verified suppliers offers a
          large range of clothing options, from casual wear to formal attire.
          Whether you require menswear, ladies' wear, or children's clothing, we
          ensure that our platform provides the latest styles with high-quality
          material. Our suppliers emphasise craftsmanship and innovation to make
          sure that what you get from them is of top quality, following the
          highest industry standards. Working with our clothing suppliers will
          add trendy, high-selling garments to your inventory. We have several
          styles, ranging from streetwear to office attire, traditional dress,
          and seasonal collections. Businesses can also request customized
          clothing solutions, bulk manufacturing, and private-label services
          from our clothing suppliers to expand their brand identity. Our
          suppliers ensure premium apparel by maintaining strict quality checks
          and using advanced production methods that meet international
          standards.
        </p>
        <p class="section-heading">
          <strong>Apparel Suppliers</strong>
        </p>
        <p class="section-content">
          GlobalB2BMart offers businesses access to leading{" "}
          <b>apparel suppliers</b>
          who specialise in different styles, textures, and materials. From
          ethnic wear and western outfits to handcrafted and designer apparel,
          our suppliers target to diverse fashion needs. Our trusted partners
          ensure superior quality and durability in each piece, making it easier
          for businesses to stock high-demand items. Sourcing apparel will never
          be easy with GlobalB2BMart since the best selection of fashion-forward
          attires will be received by retailers and wholesalers. Our apparel
          suppliers major in sustainable fashion and eco-friendly production
          methods, giving businesses what they need: ethical production of
          clothes.
        </p>
        <p class="section-content">
          The platform comes with suppliers providing sportswear, activewear,
          denim collections, and even luxury apparel in order to help meet the
          fast-growing demand for versatile clothing solutions. Our committed
          apparel suppliers enable businesses to scale their operations through
          timely delivery at competitive prices.
        </p>
        <p class="section-heading">
          <strong>Cloth Manufacturers</strong>
        </p>
        <p class="section-content">
          We provide a network of premium <b>cloth manufacturers</b> who deliver
          superior quality textiles and fabrics. You can either have raw
          materials delivered to your brands or ready-to-use fabric because our
          manufacturers give you the assurance of superior craftsmanship and
          innovative designs. With expertise in producing sustainable and
          durable fabrics, our manufacturers supply a variety of materials,
          including cotton, silk, synthetic blends, and more. Businesses can
          benefit from competitive pricing and reliable supply chains, making
          fabric sourcing stress-free and efficient. We offer an extensive range
          of textiles, including embroidered fabrics, handloom materials, and
          digital print textiles, meeting tto different industry requirements.
        </p>
        <p class="section-content">
          Businesses can source raw materials that align with their brand vision
          by working with experienced cloth manufacturers. From small-scale
          fabric requirements to bulk production, our network ensures the
          quality consistency and prompt service required, ensuring that
          businesses maintain a supply chain in their smooth flow.
        </p>

        <p class="section-heading">
          <strong>Garments Manufacturers</strong>
        </p>
        <p class="section-content">
          GlobalB2BMart introduces to you the best{" "}
          <b> garment manufacturers </b>in the industry , ensuring the best
          quality and custom-made clothing solutions. Our manufacturers
          specialize in casual wear, luxury clothing, and custom-made garments
          according to specific market requirements. With a solid focus on
          quality control and innovation, our manufacturers of garments meet the
          international demand for global trends in fashion. From mass
          productions to exclusive collections, our flexible and efficient
          manufacturing services can adapt to your needs.
        </p>
        <p class="section-content">
          We collaborate with garment producers that focus on quality,
          eco-friendly manufacturing, and the finest technology-driven solutions
          so that businesses get the best. Suppliers service private labels,
          boutique brands, and multibrand retailers that look for unique and
          varied apparel offerings. Business operations can be designed to
          customised orders like embroidery on logos, fitted orders according to
          specific needs, and fabric options to offer differentiated fashion
          products.
        </p>
        <p class="section-heading">
          <strong>Why GlobalB2BMart?</strong>
        </p>
        <p class="section-content">
          GlobalB2BMart is your ideal B2B marketplace for buying clothes and
          apparel in India. We will provide you unparalleled access to some of
          the country's most trustworthy suppliers, exporters, and
          manufacturers, with products that guarantee you quality,
          cost-effectiveness, and timely deliveries. We enable businesses to
          outsource all the fashion and textiles they require using a one-stop
          solution. As businesses continue to stay competitive in an
          ever-changing industry, GlobalB2BMart has helped various businesses
          stand tall. Authenticity, verified suppliers, and efficient purchase
          processes mark the preference for businesses that have plans to expand
          their operations through this platform.
        </p>
        <p class="section-content">
          Whether you are searching for Ladies Readymade Garments, Kurti
          Exporters, Clothing Suppliers, Apparel Suppliers, or Garments
          Manufacturers, GlobalB2BMart helps you get connected with the top
          players in the industry. Bring your business to new heights with our
          reliable network and have a strong supply chain experience. Start
          sourcing quality fashion today and establish your brand with the best
          manufacturers and suppliers in India.
        </p>
      </div>
    </>
  );
};

export default Fashion;
