import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../FreeCataloges.jsx/FreeCataloge.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGauge,
  faShieldHalved,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const products = [
  { id: 1, name: "Basmati Rice", image: "./assets/rice2.jpeg" },
  { id: 3, name: "Sona Massori Rice", image: "./assets/rice3.jpeg" },
  { id: 5, name: "Jasmine Rice", image: "./assets/rice4.jpeg" },
  { id: 6, name: "Brown Rice", image: "./assets/rice5.jpeg" },
  { id: 1, name: "Paraboiled Rice", image: "./assets/rice1.jpeg" },
  { id: 3, name: "Sona Massori Rice", image: "./assets/rice3.jpeg" },
  { id: 5, name: "Jasmine Rice", image: "./assets/rice4.jpeg" },
  { id: 6, name: "Brown Rice", image: "./assets/rice5.jpeg" },
  { id: 1, name: "Paraboiled Rice", image: "./assets/rice1.jpeg" },
];

const PortovoRice = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [highlight, setHighlight] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleReachEnd = () => {
    if (swiperInstance) {
      const isEnd = swiperInstance.isEnd;
      const navigationButton = document.querySelector(".swiper-button-next");
      if (navigationButton) {
        navigationButton.style.display = isEnd ? "none" : "block";
      }
    }
  };

  const handleReachStart = () => {
    if (swiperInstance) {
      const isBeginning = swiperInstance.isBeginning;
      const navigationButton = document.querySelector(".swiper-button-prev");
      if (navigationButton) {
        navigationButton.style.display = isBeginning ? "none" : "block";
      }
    }
  };

  const handleProductClick = () => {
    setActiveSection("products");
  };

  const renderContent = () => {
    switch (activeSection) {
      case "products":
        return (
          <div className="swiper-container">
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              slidesPerView={1}
              spaceBetween={10}
              pagination={{ clickable: true }}
              navigation={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              onSwiper={setSwiperInstance}
              onReachEnd={handleReachEnd}
              onReachBeginning={handleReachStart}
              breakpoints={{
                640: { slidesPerView: 2, spaceBetween: 20 },
                768: { slidesPerView: 4, spaceBetween: 40 },
                1024: { slidesPerView: 7, spaceBetween: 30 },
              }}
              className="mySwiper"
            >
              {products.map((product) => (
                <SwiperSlide key={`product-${product.id}`}>
                  <div className="card" onClick={handleProductClick}>
                    <img src={product.image} alt={product.name} />
                    <p>{product.name}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        );

      default:
        return (
          <div>
            <div className="swiper-container">
              <Swiper
                modules={[Autoplay, Navigation, Pagination]}
                slidesPerView={1}
                spaceBetween={10}
                pagination={{ clickable: true }}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                onSwiper={setSwiperInstance}
                onReachEnd={handleReachEnd}
                onReachBeginning={handleReachStart}
                breakpoints={{
                  640: { slidesPerView: 2, spaceBetween: 20 },
                  768: { slidesPerView: 4, spaceBetween: 40 },
                  1024: { slidesPerView: 7, spaceBetween: 30 },
                }}
                className="mySwiper"
              >
                {products.map((product) => (
                  <SwiperSlide key={`product-${product.id}`}>
                    <div className="card">
                      <img src={product.image} alt={product.name} />
                      <p>{product.name}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="welcome">
              <h1>Welcome to the PORTOVO EXIM LLP Company</h1>
              <p>
                Portovo Exim LLP is a distinguished name in the rice export
                industry, specializing in a wide variety of high-quality rice.
                Based in Kolkata, West Bengal, the company is dedicated to
                delivering premium-grade rice to global markets. With a
                commitment to quality, sustainability, and customer
                satisfaction, Portovo Exim LLP has established itself as a
                trusted supplier of diverse rice varieties, catering to
                different culinary preferences worldwide.
              </p>
              <h2>We Deals in All kind of Rice</h2>
              <p>
                Portovo Exim LLP deals in all major rice categories, ensuring
                that every grain meets stringent quality standards. Their
                product range includes: Basmati Rice – Known for its long
                grains, aromatic fragrance, and rich texture, Basmati rice is a
                preferred choice for biryanis and pilafs. Sona Masoori Rice – A
                lightweight and aromatic variety, Sona Masoori is widely used in
                South Indian cuisine for dishes like idli, dosa, and steamed
                rice. Jasmine Rice – With its floral aroma and soft texture,
                Jasmine rice is a staple in Southeast Asian cooking, ideal for
                stir-fried and steamed dishes. Brown Rice – A healthy
                alternative, Brown rice is rich in fiber and essential
                nutrients, making it a great choice for health-conscious
                consumers.
              </p>
              {/* <h2>Our Products :</h2> */}
              <p>
                Parboiled Rice – This partially boiled variety retains more
                nutrients than white rice and is highly sought after for its
                firm texture and longer shelf life.
              </p>
              <p>
                Portovo Exim LLP ensures that all rice varieties undergo strict
                quality control measures, from sourcing to packaging. The
                company prioritizes sustainable farming practices and works
                closely with farmers to maintain the highest quality standards.
                Their extensive distribution network allows them to serve both
                domestic and international markets, making them a reliable
                partner for rice importers and wholesalers worldwide.
              </p>
              {/* <p>Unmatched Expertise
                                With years of experience in the elevator industry, our skilled team brings unparalleled knowledge and proficiency to every project.</p> */}

              <li>
                <div className="pro_inner">
                  <div className="pro_img_sec">
                    <img
                      src="./assets/rice1.jpeg"
                      title="Kashmiri Saffron Threads"
                      alt="Kashmiri Saffron Threads"
                      width="202"
                      height="299"
                      decoding="async"
                    />
                  </div>
                  <div className="products_info">
                    <div className="pro_title">
                      <h3>Modern Wooden Coffee Table</h3>
                    </div>
                    <div className="pro_det_sec">
                      <ul className="attributes">
                        <li>
                          <span>Brand:</span> PORTOVO EXIM LLP
                        </li>
                        <li>
                          <span>Product:</span> Premium Basmati Rice
                        </li>
                        <li>
                          <span>Type:</span> 1121 Golden Sella Basmati - Long
                          grain, aromatic, and ideal for biryani and pulao.
                        </li>
                        <li>
                          <span>Origin:</span> Sourced from the finest farms in
                          India, ensuring premium quality and rich flavor.
                        </li>
                        <li>
                          <span>Grain Size:</span> Extra-long - Expands nearly
                          twice its size when cooked.
                        </li>
                        <li>
                          <span>Aroma:</span> Naturally aged for over 12 months
                          to enhance its signature fragrance and taste.
                        </li>
                        <li>
                          <span>Packaging:</span> Available in 5kg, 10kg, and
                          25kg sealed bags for freshness.
                        </li>
                        <li>
                          <span>Cooking Time:</span> Quick cooking - Soft and
                          fluffy in just 10-12 minutes.
                        </li>
                        <li>
                          <span>Color:</span> Golden-hued grains with a rich,
                          non-sticky texture.
                        </li>
                        <li>
                          <span>Usage:</span> Ideal for Biryani, Pulao, Fried
                          Rice, and everyday meals.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div className="company_header">
        <div className="top_head">
          <div className="company_left zdasds">
            <span className="logo_img2" style={{ background: "#d1d786" }}>
              PE
            </span>

            <div className="company_info">
              <div className="company_name2">
                <h1>
                  PORTOVO EXIM LLP{" "}
                  <div className="_oth_info">
                    <a
                      href="https://www.google.co.in/maps/dir//12.9715987,77.5945627"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <b className="fa fa-map-marker" />
                        Kolkata, West Bengal
                      </span>
                    </a>
                  </div>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_head">
          <div className={`Premium-Header ${highlight ? "highlight" : ""}`}>
            <ul className="menu_sec">
              <li>
                Home <FontAwesomeIcon icon={faGauge} />
              </li>
              <li>
                Products <FontAwesomeIcon icon={faShieldHalved} />
              </li>
              <li>
                About Us <FontAwesomeIcon icon={faMessage} />
                <ul className="dropdown"></ul>
              </li>
              <li>
                Contact Us <FontAwesomeIcon icon={faShieldHalved} />
                <ul className="dropdown"></ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {renderContent()}
    </>
  );
};

export default PortovoRice;
