import React from "react";
import { Link } from "react-router-dom";
import "./common.css";

const classifiedData = [
  {
    name: "Mohammed Aafaq",
    email: "mohammed.aafaq@gmail.com",
    mobileNumber: "9035273477",
    companyName: "MDSuhan Exports",
    productOrService: "Coffee",
    imgSrc: "/assets/coffee2.jpeg", // Ensure the image exists in your project
    altText: "Coffee - MDSuhan Exports",
    mainProducts: "Coffee",
    years: "1 YRS",
    location: "Hyderabad, India",
    tooltipText: "123, Trade Street, Hyderabad, India",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "105",
    responseRate: "96%",
  },
  {
    _id: "ak_enterprise",
    name: "Aslam Khan",
    email: "a.k.enterprises198229@gmail.com",
    password: "8375000867",
    mobileNumber: "7385069312",
    companyName: "Ak Enterprise",
    statename: "Maharashtra",
    cityname: "Aurangabad",
    productOrService: "Coffee Beans, Ladies Wear",
    imgSrc: "/assets/coffee-beans1.jpg",
    mainProducts: "Coffee Beans",
    altText: "Ak Enterprise - Supplier of Coffee Beans and Ladies Wear",
    years: "1 YRS",
    location: "Aurangabad, Maharashtra, India",
    tooltipText: "Aslam Khan - Supplier of Coffee Beans & Ladies Wear",
    rating: "4.6",
    ratingPercent: "92%",
    ratingsCount: "87",
    responseRate: "90%",
    whatsappConfirmed: true,
  },
  {
    _id: "ak_enterprise",
    name: "Aslam Khan",
    email: "a.k.enterprises198229@gmail.com",
    password: "",
    mobileNumber: "7385069312",
    companyName: "AK Enterprise",
    statename: "Maharashtra",
    cityname: "Aurangabad",
    productOrService: "Coffee Beans & Ladies Wear",
    imgSrc: "/assets/coffee5.jpg",
    mainProducts:
      "Premium Coffee Beans, Women's Ethnic Wear, Western Ladies Wear",
    altText: "AK Enterprise - Quality Coffee Beans & Trendy Ladies Wear",
    years: "1 YRS",
    location: "Aurangabad, Maharashtra, India",
    tooltipText: "Aslam Khan - Supplier of Coffee Beans & Ladies Wear",
    rating: "4.7",
    ratingPercent: "94%",
    ratingsCount: "120",
    responseRate: "89%",
    whatsappConfirmed: true,
  },
];

const CoffeeComponent = () => {
  return (
    <div className="main-box">
      <aside>
        <div className="flt-box-wrap">
          <div className="flt-box mb-0 flt-head">Filters By</div>
          <div className="flt-box bdrt-0">
            <p className="flt-title">Related Services</p>
            <div className="flt-content">
              <ul className="flt-list cust-scroll">
                <li>
                  <Link to="#">Coffee Beans</Link>
                </li>
                <li>
                  <Link to="#">Instant Coffee</Link>
                </li>
                <li>
                  <Link to="#">Ground Coffee</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flt-box">
            <p className="flt-title">By Region</p>
            <div className="flt-content">
              <div className="flt-search">
                <input
                  type="text"
                  name="region_id"
                  placeholder="Search Region"
                  id="region-search-input"
                />
              </div>
              <ul className="flt-list cust-scroll" id="region-lists">
                <li>
                  <Link to="#">All India</Link>
                </li>
                <li>
                  <Link to="#">Telangana</Link>
                </li>
                <li>
                  <Link to="#">Karnataka</Link>
                </li>
                <li>
                  <Link to="#">Tamil Nadu</Link>
                </li>
                <li>
                  <Link to="#">Maharashtra</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
      <main>
        <section className="section">
          <p className="sect-title">Explore by Products</p>
          <div className="horprd expcatg" id="expcatg">
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/coffeeex.jpeg"
                      width={55}
                      height={55}
                      alt="Coffee Export"
                    />
                  </figure>
                  <p className="title">Coffee Export</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/coffee1.jpeg"
                      width={55}
                      height={55}
                      alt="Coffee Export"
                    />
                  </figure>
                  <p className="title">Ground Coffee</p>
                </div>
              </Link>
            </div>
          </div>
        </section>
        <ul className="classfied-wrap">
          {classifiedData.map((item, index) => (
            <li key={index}>
              <div className="classified">
                <div className="prd-info">
                  <div className="prd-box">
                    <img
                      src={item.imgSrc}
                      alt={item.altText}
                      width={250}
                      height={250}
                    />
                  </div>
                </div>
                <div className="cinfo">
                  <div className="cbox">
                    <figure>
                      <span className="cmp-year">{item.years}</span>
                    </figure>
                    <div className="cboxr">
                      <Link to="#" target="_blank">
                        <h4 className="title">{item.companyName}</h4>
                      </Link>
                      <p className="cloc tooltip ellipsis">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi-location"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        {item.location}
                        <span className="tooltiptext">{item.tooltipText}</span>
                      </p>
                      <div className="rating-wrap">
                        <span className="rtbox">{item.rating}</span>
                        <span
                          className="crate"
                          style={{ "--_score": item.ratingPercent }}
                        />
                        <span className="rate-text">
                          {item.ratingsCount} Ratings
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="caddit">
                    <div className="item">
                      <div
                        className="ca-box modal-btn"
                        data-modal="motc"
                        data-src={item.trustCertificateUrl}
                      >
                        <p>
                          <i className="l3icon motc-icon" />
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="ca-box">
                        <p>
                          <i className="l3icon resp-icon" />
                        </p>
                        <p>
                          <span>Response Rate</span> <b>{item.responseRate}</b>
                        </p>
                      </div>
                      <p>
                        <span>Main Products</span> <b>{item.mainProducts}</b>
                      </p>
                    </div>
                  </div>
                  <div className="caction">
                    <Link to={"/register-buyer"}>
                      <p>Contact Supplier</p>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </main>
    </div>
  );
};

export default CoffeeComponent;
