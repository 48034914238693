import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../FreeCataloges.jsx/FreeCataloge.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGauge,
  faShieldHalved,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const products = [
  { id: 1, name: "durum weat", image: "./assets/whaet1.jpg" },
  { id: 3, name: "bread flour", image: "./assets/wheat2.jpg" },
  { id: 5, name: "semolina Wheat", image: "./assets/whaet3.jpg" },
  { id: 6, name: "couscous wheat", image: "./assets/wheat4.jpg" },
  { id: 1, name: "Paraboiled Rice", image: "./assets/rice1.jpeg" },
  { id: 3, name: "Sona Massori Rice", image: "./assets/rice3.jpeg" },
  { id: 5, name: "Jasmine Rice", image: "./assets/rice4.jpeg" },
  { id: 6, name: "Brown Rice", image: "./assets/rice5.jpeg" },
  { id: 1, name: "Paraboiled Rice", image: "./assets/rice1.jpeg" },
];

const JagdishFloors = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [highlight, setHighlight] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleReachEnd = () => {
    if (swiperInstance) {
      const isEnd = swiperInstance.isEnd;
      const navigationButton = document.querySelector(".swiper-button-next");
      if (navigationButton) {
        navigationButton.style.display = isEnd ? "none" : "block";
      }
    }
  };

  const handleReachStart = () => {
    if (swiperInstance) {
      const isBeginning = swiperInstance.isBeginning;
      const navigationButton = document.querySelector(".swiper-button-prev");
      if (navigationButton) {
        navigationButton.style.display = isBeginning ? "none" : "block";
      }
    }
  };

  const handleProductClick = () => {
    setActiveSection("products");
  };

  const renderContent = () => {
    switch (activeSection) {
      case "products":
        return (
          <div className="swiper-container">
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              slidesPerView={1}
              spaceBetween={10}
              pagination={{ clickable: true }}
              navigation={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              onSwiper={setSwiperInstance}
              onReachEnd={handleReachEnd}
              onReachBeginning={handleReachStart}
              breakpoints={{
                640: { slidesPerView: 2, spaceBetween: 20 },
                768: { slidesPerView: 4, spaceBetween: 40 },
                1024: { slidesPerView: 7, spaceBetween: 30 },
              }}
              className="mySwiper"
            >
              {products.map((product) => (
                <SwiperSlide key={`product-${product.id}`}>
                  <div className="card" onClick={handleProductClick}>
                    <img src={product.image} alt={product.name} />
                    <p>{product.name}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        );

      default:
        return (
          <div>
            <div className="swiper-container">
              <Swiper
                modules={[Autoplay, Navigation, Pagination]}
                slidesPerView={1}
                spaceBetween={10}
                pagination={{ clickable: true }}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                onSwiper={setSwiperInstance}
                onReachEnd={handleReachEnd}
                onReachBeginning={handleReachStart}
                breakpoints={{
                  640: { slidesPerView: 2, spaceBetween: 20 },
                  768: { slidesPerView: 4, spaceBetween: 40 },
                  1024: { slidesPerView: 7, spaceBetween: 30 },
                }}
                className="mySwiper"
              >
                {products.map((product) => (
                  <SwiperSlide key={`product-${product.id}`}>
                    <div className="card">
                      <img src={product.image} alt={product.name} />
                      <p>{product.name}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="welcome">
              <h1>Welcome to the JAGADEES AGENCIES Company</h1>
              Flour is one of the most fundamental ingredients in kitchens
              worldwide, serving as the backbone for countless recipes, from
              bread and pastries to cakes and pasta. It is derived from grinding
              grains, primarily wheat, but can also be made from corn, rice,
              millet, and other sources. At JAGADEES AGENCIES Company, we take
              pride in producing high-quality flour that meets the diverse needs
              of households, bakeries, and food industries.
              <h2>We Deals in All kind of Wheat</h2>
              <p>
                Flour comes in various types, each suited for different culinary
                applications: Wheat Flour: The most common variety, used in
                baking bread, chapatis, and cakes. It contains gluten, which
                provides elasticity to dough. All-Purpose Flour: Versatile and
                suitable for most baking needs. Whole Wheat Flour: Made from the
                entire wheat kernel, rich in fiber and nutrients. Rice Flour:
                Gluten-free and used in many Asian dishes and snacks. Maida
                (Refined Flour): A finely milled flour used for making pastries,
                biscuits, and noodles. Gram Flour (Besan): Made from chickpeas
                and commonly used in Indian cuisine.
              </p>
              <p>
                The Importance of Quality Flour Flour quality directly impacts
                the texture, taste, and nutritional value of food. At Jayantipur
                Flour Mill Company, we ensure that our flour is finely milled,
                free from impurities, and retains its natural goodness. Our
                state-of-the-art milling process guarantees consistency,
                freshness, and hygiene, making our products ideal for home cooks
                and large-scale food production.
              </p>
              <p>
                JAGADEES AGENCIES ensures that all rice varieties undergo strict
                quality control measures, from sourcing to packaging. The
                company prioritizes sustainable farming practices and works
                closely with farmers to maintain the highest quality standards.
                Their extensive distribution network allows them to serve both
                domestic and international markets, making them a reliable
                partner for rice importers and wholesalers worldwide.
              </p>
              {/* <p>Unmatched Expertise
                                With years of experience in the elevator industry, our skilled team brings unparalleled knowledge and proficiency to every project.</p> */}
              <li>
                <div className="pro_inner">
                  <div className="pro_img_sec">
                    <img
                      src="./assets/flour1.jpeg"
                      title="Kashmiri Saffron Threads"
                      alt="Kashmiri Saffron Threads"
                      width="202"
                      height="299"
                      decoding="async"
                    />
                  </div>
                  <div className="products_info">
                    <div className="pro_title">
                      <h3>Premium Quality Wheat Flour</h3>
                    </div>

                    <ul className="attributes">
                      <li>
                        <span>Brand:</span> JAGADEES AGENCIES
                      </li>
                      <li>
                        <span>Product:</span> High-Quality Wheat Flour (Atta)
                      </li>
                      <li>
                        <span>Type:</span> Stone-ground whole wheat flour – Rich
                        in fiber and essential nutrients.
                      </li>
                      <li>
                        <span>Origin:</span> Sourced from the finest wheat farms
                        in India, ensuring superior quality and purity.
                      </li>
                      <li>
                        <span>Texture:</span> Soft, finely milled, and perfect
                        for making fluffy chapatis and rotis.
                      </li>
                      <li>
                        <span>Nutrition:</span> Naturally rich in fiber,
                        proteins, and essential vitamins for a healthy diet.
                      </li>
                      <li>
                        <span>Packaging:</span> Available in 5kg, 10kg, and 25kg
                        sealed bags to maintain freshness.
                      </li>
                      <li>
                        <span>Cooking Quality:</span> Absorbs water efficiently,
                        making dough soft and easy to knead.
                      </li>
                      <li>
                        <span>Color:</span> Light golden-brown, indicating
                        high-quality whole wheat content.
                      </li>
                      <li>
                        <span>Usage:</span> Ideal for Chapatis, Parathas,
                        Breads, and other baked goods.
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div className="company_header">
        <div className="top_head">
          <div className="company_left zdasds">
            <span className="logo_img2" style={{ background: "#d1d786" }}>
              JF
            </span>

            <div className="company_info">
              <div className="company_name2">
                <h1>
                  JAGADEES AGENCIES{" "}
                  <div className="_oth_info">
                    <a
                      href="https://www.google.co.in/maps/dir//12.9715987,77.5945627"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <b className="fa fa-map-marker" />
                        Hyderabad, India
                      </span>
                    </a>
                  </div>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_head">
          <div className={`Premium-Header ${highlight ? "highlight" : ""}`}>
            <ul className="menu_sec">
              <li>
                Home <FontAwesomeIcon icon={faGauge} />
              </li>
              <li>
                Products <FontAwesomeIcon icon={faShieldHalved} />
              </li>
              <li>
                About Us <FontAwesomeIcon icon={faMessage} />
                <ul className="dropdown"></ul>
              </li>
              <li>
                Contact Us <FontAwesomeIcon icon={faShieldHalved} />
                <ul className="dropdown"></ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {renderContent()}
    </>
  );
};

export default JagdishFloors;
