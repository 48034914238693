import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../FreeCataloges.jsx/FreeCataloge.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGauge,
  faShieldHalved,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const products = [
  { id: 1, name: "Tomato", image: "./assets/tomato1.jpg" },
  { id: 3, name: "Pea", image: "./assets/pea2.jpg" },
  { id: 5, name: " Rdish", image: "./assets/Radish1.jpg" },
  { id: 6, name: "Cabbage", image: "./assets/cabbage1.jpg" },
  { id: 1, name: "Potato", image: "./assets/Potato1.jpg" },
  { id: 3, name: "Onion", image: "./assets/onion7.jpg" },
  { id: 5, name: "Lemon", image: "./assets/lemon.jpg" },
  { id: 6, name: "spinach", image: "./assets/spinich.jpg" },
];

const LakshmiCatalogue = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [highlight, setHighlight] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleReachEnd = () => {
    if (swiperInstance) {
      const isEnd = swiperInstance.isEnd;
      const navigationButton = document.querySelector(".swiper-button-next");
      if (navigationButton) {
        navigationButton.style.display = isEnd ? "none" : "block";
      }
    }
  };

  const handleReachStart = () => {
    if (swiperInstance) {
      const isBeginning = swiperInstance.isBeginning;
      const navigationButton = document.querySelector(".swiper-button-prev");
      if (navigationButton) {
        navigationButton.style.display = isBeginning ? "none" : "block";
      }
    }
  };

  const handleProductClick = () => {
    setActiveSection("products");
  };

  const renderContent = () => {
    switch (activeSection) {
      case "products":
        return (
          <div className="swiper-container">
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              slidesPerView={1}
              spaceBetween={10}
              pagination={{ clickable: true }}
              navigation={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              onSwiper={setSwiperInstance}
              onReachEnd={handleReachEnd}
              onReachBeginning={handleReachStart}
              breakpoints={{
                640: { slidesPerView: 2, spaceBetween: 20 },
                768: { slidesPerView: 4, spaceBetween: 40 },
                1024: { slidesPerView: 7, spaceBetween: 30 },
              }}
              className="mySwiper"
            >
              {products.map((product) => (
                <SwiperSlide key={`product-${product.id}`}>
                  <div className="card" onClick={handleProductClick}>
                    <img src={product.image} alt={product.name} />
                    <p>{product.name}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        );

      default:
        return (
          <div>
            <div className="swiper-container">
              <Swiper
                modules={[Autoplay, Navigation, Pagination]}
                slidesPerView={1}
                spaceBetween={10}
                pagination={{ clickable: true }}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                onSwiper={setSwiperInstance}
                onReachEnd={handleReachEnd}
                onReachBeginning={handleReachStart}
                breakpoints={{
                  640: { slidesPerView: 2, spaceBetween: 20 },
                  768: { slidesPerView: 4, spaceBetween: 40 },
                  1024: { slidesPerView: 7, spaceBetween: 30 },
                }}
                className="mySwiper"
              >
                {products.map((product) => (
                  <SwiperSlide key={`product-${product.id}`}>
                    <div className="card">
                      <img src={product.image} alt={product.name} />
                      <p>{product.name}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="welcome">
              <h1>Welcome to the Lakshmi Sri Enterprises Company</h1>
              <p>
                Lakshmi Sri Enterprises is a trusted name in the agricultural
                industry, specializing in the supply and distribution of fresh,
                high-quality vegetables. With a commitment to excellence and
                sustainability, we ensure that our produce meets the highest
                standards of freshness, nutrition, and quality.
              </p>
              <h2>We Deals in All kind of Vegetables</h2>
              <p>
                Lakshmi Sri Enterprises, we prioritize quality control at every
                stage, from harvesting and sorting to packaging and
                distribution. Our vegetables are carefully selected and handled
                under hygienic conditions to maintain their natural taste and
                nutritional value. With a strong distribution network, we ensure
                timely delivery, keeping freshness intact. Sustainability is at
                the heart of our operations. We support eco-friendly farming
                practices and work closely with farmers to promote organic and
                pesticide-free cultivation. By maintaining fair trade practices,
                we contribute to the well-being of farmers while providing
                customers with safe and healthy produce.
              </p>
              {/* <h2>Our Products :</h2> */}
              <p>
                Customer satisfaction is our top priority. Our commitment to
                providing fresh, high-quality vegetables at competitive prices
                has helped us build strong relationships with our clients.
                Whether you need bulk supplies for commercial purposes or fresh
                produce for daily consumption, Lakshmi Sri Enterprises is your
                reliable partner. Choose Lakshmi Sri Enterprises for
                premium-quality vegetables and experience the freshness, taste,
                and reliability that set us apart. We are dedicated to
                delivering the best farm-fresh produce to your doorstep.
              </p>

              {/* <p>Unmatched Expertise
                                With years of experience in the elevator industry, our skilled team brings unparalleled knowledge and proficiency to every project.</p> */}

              <li>
                <div className="pro_inner">
                  <div className="pro_img_sec">
                    <img
                      src="./assets/onion7.jpg"
                      title="Kashmiri Saffron Threads"
                      alt="Kashmiri Saffron Threads"
                      width="202"
                      height="299"
                      decoding="async"
                    />
                  </div>
                  <div className="products_info">
                    <div className="pro_title">
                      <h3>Fresh Organic Vegetables</h3>
                    </div>
                    <div className="pro_det_sec">
                      <ul className="attributes">
                        <li>
                          <span>Brand:</span> Ai Noor Vegetable Seller
                        </li>
                        <li>
                          <span>Product:</span> Fresh Organic Vegetables
                        </li>
                        <li>
                          <span>Type:</span> A wide variety of seasonal and
                          year-round vegetables, sourced directly from local
                          farms
                        </li>
                        <li>
                          <span>Quality:</span> 100% organic, pesticide-free,
                          and grown using sustainable farming practices
                        </li>
                        <li>
                          <span>Availability:</span> Daily fresh stock -
                          Ensuring you get the freshest produce every time
                        </li>
                        <li>
                          <span>Packaging:</span> Eco-friendly packaging -
                          Designed to keep vegetables fresh and reduce
                          environmental impact
                        </li>
                        <li>
                          <span>Variety:</span> Includes leafy greens, root
                          vegetables, and exotic options - Catering to all
                          culinary needs
                        </li>
                        <li>
                          <span>Delivery:</span> Home delivery available - Fresh
                          vegetables delivered straight to your doorstep
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div className="company_header">
        <div className="top_head">
          <div className="company_left zdasds">
            <span className="logo_img2" style={{ background: "#d1d786" }}>
              LE
            </span>

            <div className="company_info">
              <div className="company_name2">
                <h1>
                  Lakshmi Sri Enterprises{" "}
                  <div className="_oth_info">
                    <a
                      href="https://www.google.co.in/maps/dir//12.9715987,77.5945627"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <b className="fa fa-map-marker" />
                        Kallidaikurichi, Tamil Nadu, India{" "}
                      </span>
                    </a>
                  </div>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_head">
          <div className={`Premium-Header ${highlight ? "highlight" : ""}`}>
            <ul className="menu_sec">
              <li>
                Home <FontAwesomeIcon icon={faGauge} />
              </li>
              <li>
                Products <FontAwesomeIcon icon={faShieldHalved} />
              </li>
              <li>
                About Us <FontAwesomeIcon icon={faMessage} />
                <ul className="dropdown"></ul>
              </li>
              <li>
                Contact Us <FontAwesomeIcon icon={faShieldHalved} />
                <ul className="dropdown"></ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {renderContent()}
    </>
  );
};

export default LakshmiCatalogue;
