import React, { useState, useEffect } from "react";
import "./OnionBuy.css"; // Reuse the existing CSS or create a new one as per your need
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import Whyglobal from "../Components/Whyglobal";

// Sample random data for bags buyers
const BagsBuys = [
  {
    _id: "2b3c4d5e6f7g8h9i0j1k2l3m",
    name: "Rajiv Mehta",
    email: "rajiv.mehta@example.com",
    mobileNumber: "9234567890",
    lookingFor: "Need Customized Jute Bags for Corporate Gifting",
    locationandDate: "Delhi, 07-02-2025",
    productOrService: "Bag Supplier",
    quantity: 5000,
    unit: "pieces",
  },
  {
    _id: "3c4d5e6f7g8h9i0j1k2l3m4n",
    name: "Sonia Kapoor",
    email: "sonia.kapoor@example.com",
    mobileNumber: "9345678901",
    lookingFor: "Looking for Eco-Friendly Cotton Tote Bags in Bulk",
    locationandDate: "Bangalore, 06-02-2025",
    productOrService: "Bag Supplier",
    quantity: 7000,
    unit: "pieces",
  },
  {
    _id: "4d5e6f7g8h9i0j1k2l3m4n5o",
    name: "Amit Verma",
    email: "amit.verma@example.com",
    mobileNumber: "9456789012",
    lookingFor: "Require Large Quantity of Promotional Carry Bags",
    locationandDate: "Chennai, 05-02-2025",
    productOrService: "Bag Supplier",
    quantity: 6000,
    unit: "pieces",
  },
  {
    _id: "5e6f7g8h9i0j1k2l3m4n5o6p",
    name: "Pooja Iyer",
    email: "pooja.iyer@example.com",
    mobileNumber: "9567890123",
    lookingFor: "Seeking High-Quality Leather Laptop Bags",
    locationandDate: "Hyderabad, 04-02-2025",
    productOrService: "Bag Supplier",
    quantity: 2000,
    unit: "pieces",
  },
  {
    _id: "6f7g8h9i0j1k2l3m4n5o6p7q",
    name: "Vikas Malhotra",
    email: "vikas.malhotra@example.com",
    mobileNumber: "9678901234",
    lookingFor: "Interested in Buying Bulk School Backpacks",
    locationandDate: "Kolkata, 03-02-2025",
    productOrService: "Bag Supplier",
    quantity: 8000,
    unit: "pieces",
  },
  {
    _id: "7g8h9i0j1k2l3m4n5o6p7q8r",
    name: "Neha Joshi",
    email: "neha.joshi@example.com",
    mobileNumber: "9789012345",
    lookingFor: "Wholesale Purchase of Travel Duffel Bags",
    locationandDate: "Pune, 02-02-2025",
    productOrService: "Bag Supplier",
    quantity: 4000,
    unit: "pieces",
  },
  {
    _id: "8h9i0j1k2l3m4n5o6p7q8r9s",
    name: "Sandeep Khanna",
    email: "sandeep.khanna@example.com",
    mobileNumber: "9890123456",
    lookingFor: "Need Stylish Handbags for Fashion Retail",
    locationandDate: "Ahmedabad, 01-02-2025",
    productOrService: "Bag Supplier",
    quantity: 3500,
    unit: "pieces",
  },
  {
    _id: "1a1b2c3d4e5f6g7h8i9j0k1l",
    name: "Anita Sharma",
    email: "anita.sharma@example.com",
    mobileNumber: "9123456789",
    lookingFor: "Bulk Purchase: High Quality Canvas Bags Required",
    locationandDate: "Mumbai, 25-11-2024",
    productOrService: "Bag Supplier",
    quantity: 3000,
    unit: "pieces",
  },
  {
    _id: "2b2c3d4e5f6g7h8i9j0k1l2m",
    name: "Ravi Mehta",
    email: "ravi.mehta@example.com",
    mobileNumber: "9988776655",
    lookingFor: "Bulk Purchase: Premium Leather Bags Required",
    locationandDate: "Bangalore, 26-11-2024",
    productOrService: "Bag Supplier",
    quantity: 2000,
    unit: "pieces",
  },
  {
    _id: "3c3d4e5f6g7h8i9j0k1l2m3n",
    name: "Priya Patel",
    email: "priya.patel@example.com",
    mobileNumber: "9345678901",
    lookingFor: "Bulk Purchase: Eco-Friendly Jute Bags Required",
    locationandDate: "Delhi, 27-11-2024",
    productOrService: "Bag Supplier",
    quantity: 5000,
    unit: "pieces",
  },
  {
    _id: "4d4e5f6g7h8i9j0k1l2m3n4o",
    name: "Vikram Singh",
    email: "vikram.singh@example.com",
    mobileNumber: "9501234567",
    lookingFor: "Bulk Purchase: Fashionable Tote Bags Required",
    locationandDate: "Chennai, 29-11-2024",
    productOrService: "Bag Supplier",
    quantity: 1500,
    unit: "pieces",
  },
  {
    _id: "5e5f6g7h8i9j0k1l2m3n4o5p",
    name: "Sushila Yadav",
    email: "sushila.yadav@example.com",
    mobileNumber: "8887776666",
    lookingFor: "Bulk Purchase: Waterproof Travel Bags Required",
    locationandDate: "Kolkata, 02-12-2024",
    productOrService: "Bag Supplier",
    quantity: 1000,
    unit: "pieces",
  },

  {
    _id: "66901a299f2cecbc7cc11450",
    name: "Rakesh",
    email: "rakesh.kumar@gmail.com",
    mobileNumber: "9876543210",
    lookingFor: "Bulk Purchase: High Quality Jute Bags Required",
    locationandDate: "Delhi, 17-10-2024",
    productOrService: "Bag Supplier",
    quantity: 5000,
    unit: "pieces",
  },
  {
    _id: "66901a299f2cecbc7cc11451",
    name: "Seema",
    email: "seema.sharma@gmail.com",
    mobileNumber: "9987654322",
    lookingFor: "Urgent Requirement: Cotton Bags in Bulk",
    locationandDate: "Ahmedabad, 16-10-2024",
    productOrService: "Bag Dealer",
    quantity: 3000,
    unit: "pieces",
  },
  {
    _id: "66901a299f2cecbc7cc11452",
    name: "Anil",
    email: "anil.mahajan@gmail.com",
    mobileNumber: "9123456788",
    lookingFor: "Immediate Need: Eco-friendly Plastic Bags",
    locationandDate: "Bangalore, 15-10-2024",
    productOrService: "Eco Bags Wholesaler",
    quantity: 10000,
    unit: "pieces",
  },
  {
    _id: "commercial-packaging-printed-poly-bag-supplier-tirupur",
    name: "Niranjayanusha",
    email: "8428242828@gmail.com",
    password: "8428242828",
    mobileNumber: "8428242828",
    companyName: "Commercial Packaging And Printed Poly Bag Supplier",
    productOrService: "Commercial Packaging And Printed Poly Bag Supplier",
    imgSrc: "/assets/commercial-bags.jpg",
    mainProducts: "Commercial Packaging And Printed Poly Bags",
    altText: "Commercial Packaging And Printed Poly Bag Supplier - Tirupur",
    years: "1 YRS", // Random value
    location: "Tirupur, Tamil Nadu, India",
    tooltipText: "Commercial Packaging And Printed Poly Bag Supplier, Tirupur",
    rating: "4.8", // Random value
    ratingPercent: "96%", // Random value
    ratingsCount: "45", // Random value
    responseRate: "92%", // Random value
    whatsappConfirmed: true,
  },
];

const BagsBuy = () => {
  const { userId } = useAuth();
  const [userIsPremium, setUserIsPremium] = useState(false);
  const [shownNumbers, setShownNumbers] = useState([]);
  const [leadsViewed, setLeadsViewed] = useState(0);

  useEffect(() => {
    if (!userId) return;

    // Load the viewed leads from local storage
    const storedViewedLeads =
      JSON.parse(localStorage.getItem(`bagsViewedLeads_${userId}`)) || [];
    setShownNumbers(storedViewedLeads);

    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/getUserWithPremiumStatus/${userId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserIsPremium(data.isPremium);
        setLeadsViewed(data.leadsViewed);
        console.log("Premium Status:", data.isPremium);
      })
      .catch((error) => {
        console.error("Error fetching user information:", error);
      });
  }, [userId]);

  const toggleNumber = async (index) => {
    if (!userIsPremium) return; // Only premium users can see numbers

    if (leadsViewed >= 25) {
      alert("You have reached the limit of 25 leads viewed per month.");
      return;
    }

    if (shownNumbers.includes(index)) return; // If already shown, do nothing

    const updatedShownNumbers = [...shownNumbers, index];
    setShownNumbers(updatedShownNumbers);

    // Update local storage
    localStorage.setItem(
      `bagsViewedLeads_${userId}`,
      JSON.stringify(updatedShownNumbers)
    );

    const buyer = BagsBuys[index]; // Get the buyer information

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/incrementLeadsViewed/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            buyerName: buyer.name,
            mobileNo: buyer.mobileNumber,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setLeadsViewed(data.leadsViewed);
      } else {
        const errorData = await response.json();
        console.error("Error incrementing leads viewed:", errorData.error);
      }
    } catch (error) {
      console.error("Error incrementing leads viewed:", error.message);
    }
  };

  return (
    <>
      <div className="container">
        <ol className="breadcrumb">
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px 30px",
            }}
            className="breadcrumb-item"
          >
            <Link to={"/"}>Home</Link>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px -25px",
            }}
          >
            /
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px 30px",
            }}
            className="breadcrumb-item active"
          >
            Fresh Leads
          </li>
        </ol>
      </div>
      <div className="containersx">
        <div style={{ width: "200px", padding: "10px" }} className="browsers">
          <h3 style={{ marginTop: "30px" }}>Browse by State</h3>
          <ul className="flt-list cust-scroll" id="state-lists">
            <li>
              <Link to="#">All India</Link>
            </li>
            <li>
              <Link to="#">Delhi</Link>
            </li>
            <li>
              <Link to="#">Maharashtra</Link>
            </li>
            <li>
              <Link to="#">Karnataka</Link>
            </li>
            <li>
              <Link to="#">West Bengal</Link>
            </li>
            <li>
              <Link to="#">Tamil Nadu</Link>
            </li>
          </ul>

          <ul style={{ marginTop: "20px" }} className="flt-list cust-scroll">
            <h3>All Category</h3>
            <li>
              <Link to="#">Jute Bags</Link>
            </li>
            <li>
              <Link to="#">Cotton Bags</Link>
            </li>
            <li>
              <Link to="#">Eco-friendly Bags</Link>
            </li>
            <li>
              <Link to="#">Paper Bags</Link>
            </li>
            <li>
              <Link to="#">Rice Bags</Link>
            </li>
          </ul>
        </div>
        <div className="buyers">
          {BagsBuys.map((buyer, index) => (
            <div key={index} className="buyer-card">
              <h2 style={{ color: "red" }}>{buyer.lookingFor}</h2>
              <p>
                <strong>Buyer Name:</strong> {buyer.name}
              </p>
              <p>
                <strong>Quantity:</strong> {buyer.quantity} {buyer.unit}
              </p>
              <p>
                <strong>Location & Date:</strong> {buyer.locationandDate}
              </p>
              <p>
                <strong>Mobile No.:</strong>{" "}
                {userIsPremium
                  ? shownNumbers.includes(index)
                    ? buyer.mobileNumber
                    : "********"
                  : "********"}
              </p>
              <div style={{ display: "flex", gap: "10px" }}>
                {userIsPremium && (
                  <button
                    className="detail-btn"
                    style={{
                      padding: "5px 10px",
                      background: "#f7f1f1",
                      color: "orange",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleNumber(index)}
                  >
                    Click to see
                  </button>
                )}
              </div>
              <div className="btns-imp">
                <Link to={"/login"}>
                  <button className="detailing-btn">View More</button>
                </Link>
                <Link to={"/register-Company"}>
                  <button className="regis-btn">Register Now</button>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="sidebar">
          <div className="mains-slide">
            <div className="tk23-hkCont_onergt">
              <div className="tk23-hkCont_premserv__box">
                <h1 className="tk23-hkCont_premserv__ttl">
                  Our Membership Plans
                </h1>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Standard Plan
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Premium Plan
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="tk23-hkCont_premserv__list">
                  <Link to={"/packages"}>
                    <div className="tk23-hkCont_premservs">
                      <span
                        className="fontss"
                        style={{ backgroundColor: "#fff" }}
                      >
                        Advance Plan
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Whyglobal />
      </div>
    </>
  );
};

export default BagsBuy;
