// import React from 'react';
// import './main-cat.css';
// import { Link } from 'react-router-dom';

// const classifiedData = [
//     {
//         imgSrc: "/assets/yellowonion.jpeg",
//         altText: "Fresh Red Onion, Specialty : No Artificial Flavour - Shree Narayan Kishan Kendra",
//         years: "1 YRS",
//         title: "Shree Narayan Kishan Kendra",
//         location: "Jaipur, Rajasthan, India",
//         tooltipText: "Dehara, Mahar Kalan, Via-samod, Chomu, -303806, Jaipur, Rajasthan, India",
//         rating: "3.9",
//         ratingPercent: "78%",
//         ratingsCount: "20",
//         trustCertificateUrl: "#",
//         responseRate: "54%"
//     },
//     {
//         "name": "Mr. Sandesh Durgawale",
//         "email": "sandeshdurgawale@gmail.com",
//         "password": "Sandesh@123",
//         "mobileNumber": "7499228062",
//         "companyName": "Sandesh Durgawale",
//         "productOrService": "Onion Seeds",
//         "imgSrc": "/assets/spices2.jpg",  // Provide the correct image path
//         "altText": "Onion Seeds - Sandesh Durgawale",
//         "mainProducts": "High-Quality Onion Seeds, Agricultural Products",
//         "years": "1 YRS",
//         "location": "Nagpur, India",
//         "tooltipText": "789 Seed Lane, Agricultural Park, Nagpur, India",
//         "rating": "4.7",
//         "ratingPercent": "94%",
//         "ratingsCount": "85",
//         "responseRate": "88%"
//     }

// {
//     imgSrc: "/assets/freshonion.jpeg",
//     altText: "Fresh Red Onion, Specialty : No Artificial Flavour - Shree Narayan Kishan Kendra",
//     years: "2 YRS",
//     title: "Shree Narayan Kishan Kendra",
//     location: "Jaipur, Rajasthan, India",
//     tooltipText: "Dehara, Mahar Kalan, Via-samod, Chomu, -303806, Jaipur, Rajasthan, India",
//     rating: "3.9",
//     ratingPercent: "78%",
//     ratingsCount: "20",
//     trustCertificateUrl: "#",
//     responseRate: "54%"
// },
// {
//     imgSrc: "/assets/whiteonion.jpeg",
//     altText: "Fresh Red Onion, Specialty : No Artificial Flavour - Shree Narayan Kishan Kendra",
//     years: "2 YRS",
//     title: "Shree Narayan Kishan Kendra",
//     location: "Jaipur, Rajasthan, India",
//     tooltipText: "Dehara, Mahar Kalan, Via-samod, Chomu, -303806, Jaipur, Rajasthan, India",
//     rating: "3.9",
//     ratingPercent: "78%",
//     ratingsCount: "20",
//     trustCertificateUrl: "#",
//     responseRate: "54%"
// },
// {
//     imgSrc: "/assets/freshonion.jpeg",
//     altText: "Fresh Red Onion, Specialty : No Artificial Flavour - Shree Narayan Kishan Kendra",
//     years: "2 YRS",
//     title: "Shree Narayan Kishan Kendra",
//     location: "Jaipur, Rajasthan, India",
//     tooltipText: "Dehara, Mahar Kalan, Via-samod, Chomu, -303806, Jaipur, Rajasthan, India",
//     rating: "3.9",
//     ratingPercent: "78%",
//     ratingsCount: "20",

//     responseRate: "54%"
// },

// ];

// const MainRice = () => {
//     return (
//         <div className="main-box">
//             <aside>
//                 <div className="flt-box-wrap">
//                     <div className="flt-box mb-0 flt-head">Filters By</div>
//                     <div className="flt-box bdrt-0">
//                         <p className="flt-title">
//                             Related Categories

//                         </p>
//                         <div className="flt-content">
//                             <ul className="flt-list cust-scroll">
//                                 <li><a href="#">Fresh Onion</a></li>
//                                 <li><a href="#">Fresh White Onion</a></li>
//                                 <li><a href="#">Red Onion</a></li>
//                                 <li><a href="#">Red Onion Flakes</a></li>
//                                 <li><a href="#">Red Big Onion</a></li>
//                                 <li><a href="#">Dehydrated Onion</a></li>
//                                 <li><a href="#">White Onion</a></li>
//                                 <li><a href="#">Onion Flakes</a></li>
//                             </ul>
//                         </div>
//                     </div>
//                     <div className="flt-box">
//                         <p className="flt-title">
//                             By State

//                         </p>
//                         <div className="flt-content">
//                             <div className="flt-search">
//                                 <input type="text" name="state_id" placeholder="Search State" id="state-search-input" />

//                             </div>
//                             <ul className="flt-list cust-scroll" id="state-lists">
//                                 <li><a href="#">All India</a></li>
//                                 <li><a href="#">Maharashtra</a></li>
//                                 <li><a href="#">Tamil Nadu</a></li>
//                                 <li><a href="#">Gujarat</a></li>
//                                 <li><a href="#">Madhya Pradesh</a></li>
//                                 <li><a href="#">Uttar Pradesh</a></li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//             </aside>
//             <main>
//                 <section className="section">
//                     <p className="sect-title">Explore by Categories</p>
//                     <div className="horprd expcatg" id="expcatg">
//                         <div className="item">
//                             <a href="#">
//                                 <div className="horprd-box">
//                                     <figure>
//                                         <img src="/assets/onion1.jpeg" width={55} height={55} alt="Dehydrated Onion" />
//                                     </figure>
//                                     <p className="title">Dehydrated Onion</p>
//                                 </div>
//                             </a>
//                         </div>
//                         <div className="item">
//                             <a href="#">
//                                 <div className="horprd-box">
//                                     <figure>
//                                         <img src="/assets/onion2.jpeg" width={55} height={55} alt="Red Onion" />
//                                     </figure>
//                                     <p className="title">Red Onion</p>
//                                 </div>
//                             </a>
//                         </div>
//                         <div className="item">
//                             <a href="#">
//                                 <div className="horprd-box">
//                                     <figure>
//                                         <img src="/assets/whiteonion.jpeg" width={55} height={55} alt="White Onion" />
//                                     </figure>
//                                     <p className="title">White Onion</p>
//                                 </div>
//                             </a>
//                         </div>
//                         <div className="item">
//                             <a href="#">
//                                 <div className="horprd-box">
//                                     <figure>
//                                         <img src="/assets/freshonion.jpeg" width={55} height={55} alt="Fresh Onion" />
//                                     </figure>
//                                     <p className="title">Fresh Onion</p>
//                                 </div>
//                             </a>
//                         </div>
//                         <div className="item">
//                             <a href="#">
//                                 <div className="horprd-box">
//                                     <figure>
//                                         <img src="/assets/freshonion.jpeg" width={55} height={55} alt="Fresh Red Onion" />
//                                     </figure>
//                                     <p className="title">Fresh Red Onion</p>
//                                 </div>
//                             </a>
//                         </div>
//                         <div className="item">
//                             <a href="#">
//                                 <div className="horprd-box">
//                                     <figure>
//                                         <img src="/assets/yellowonion.jpeg" width={55} height={55} alt="Yellow Onion" />
//                                     </figure>
//                                     <p className="title">Yellow Onion</p>
//                                 </div>
//                             </a>
//                         </div>
//                         <div className="item">
//                             <a href="#">
//                                 <div className="horprd-box">
//                                     <figure>
//                                         <img src="/assets/whiteonion.jpeg" width={55} height={55} alt="Fresh White Onion" />
//                                     </figure>
//                                     <p className="title">Fresh White Onion</p>
//                                 </div>
//                             </a>
//                         </div>
//                         <div className="item">
//                             <a href="#">
//                                 <div className="horprd-box">
//                                     <figure>
//                                         <img src="/assets/driedonion.jpeg" width={55} height={55} alt="Dried Onion" loading="lazy" />
//                                     </figure>
//                                     <p className="title">Dried Onion</p>
//                                 </div>
//                             </a>
//                         </div>

//                     </div>

//                 </section>
//                 <ul className="classfied-wrap">
//                     {classifiedData.map((item, index) => (
//                         <li key={index}>
//                             <div className="classified">
//                                 <div className="prd-info">
//                                     <div className="prd-box">
//                                         <img src={item.imgSrc} alt={item.altText} width={250} height={250} />
//                                     </div>
//                                 </div>
//                                 <div className="cinfo">
//                                     <div className="cbox">
//                                         <figure>
//                                             <span className="cmp-year">{item.years}</span>
//                                         </figure>
//                                         <div className="cboxr">
//                                             <a href="#" target="_blank"><h4 className="title">{item.title}</h4></a>
//                                             <p className="cloc tooltip ellipsis">
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi-location" viewBox="0 0 16 16">
//                                                     <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
//                                                 </svg>
//                                                 {item.location}
//                                                 <span className="tooltiptext">{item.tooltipText}</span>
//                                             </p>
//                                             <div className="rating-wrap">
//                                                 <span className="rtbox">{item.rating}</span>
//                                                 <span className="crate" style={{ "--_score": item.ratingPercent }} />
//                                                 <span className="rate-text">{item.ratingsCount} Ratings</span>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="caddit">
//                                         <div className="item">
//                                             <div className="ca-box modal-btn" data-modal="motc" data-src={item.trustCertificateUrl}>
//                                                 <p><i className="l3icon motc-icon" /></p>
//                                                 {/* <p>Member of Trust Certificate</p> */}
//                                             </div>
//                                         </div>
//                                         <div className="item">
//                                             <div className="ca-box">
//                                                 <p><i className="l3icon resp-icon" /></p>
//                                                 <p><span>Response Rate</span> <b>{item.responseRate}</b></p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="caction">
//                                         {/* <a href="">
//                                             <p>Contact Supplier</p>
//                                         </a> */}
//                                         <Link to={'/'}><p>Contact Supplier</p></Link>
//                                     </div>
//                                 </div>
//                             </div>
//                         </li>
//                     ))}
//                 </ul>
//             </main>
//         </div>
//     );
// }

// export default MainRice;
// import React from 'react';
// import { Link } from 'react-router-dom';
// import './main-cat.css';
// <head>
//     <title>contact the best suppliers of onion seeds & dehydrated onions</title>
//     <meta name="description"
//         content="connect with the best suppliers of onion seeds,onion powder,dehydrated onions,white onion,red onion with globalb2bmart. we are the best b2b portal in india" />
// </head>
// const classifiedData = [
//     {
//         "name": "Mr. Sandesh Durgawale",
//         "email": "sandeshdurgawale@gmail.com",
//         "password": "Sandesh@123",
//         "mobileNumber": "7499228062",
//         "companyName": "Sandesh Durgawale",
//         "productOrService": "Onion Seeds",
//         "imgSrc": "/assets/yellowonion.jpeg",
//         "altText": "Onion Seeds - Sandesh Durgawale",
//         "mainProducts": "High-Quality Onion Seeds, Agricultural Products",
//         "years": "1 YRS",
//         "location": "Nagpur, India",
//         "tooltipText": "789 Seed Lane, Agricultural Park, Nagpur, India",
//         "rating": "4.7",
//         "ratingPercent": "94%",
//         "ratingsCount": "85",
//         "responseRate": "88%"
//     },
//     {
//         "_id": "a901234567890abcdef0123", // Random unique ID
//         "name": "Babubhai",
//         "email": "babubhai@gmail.com",
//         "password": "babubhai", // Including the password as specified
//         "mobileNumber": "9925096366",
//         "companyName": "Kishan Exim",
//         "productOrService": "Onion Powder",
//         "imgSrc": "/assets/onionpowder.jpeg",  // Example image path
//         "mainProducts": "Onion Powder, Dehydrated Onions, Garlic Powder",
//         "altText": "Onion Powder - Kishan Exim",
//         "years": "1 YRS",
//         "location": "Gujarat, India",
//         "tooltipText": "123 Agro Street, Gujarat, India",
//         "rating": "4.4",
//         "ratingPercent": "89%",
//         "ratingsCount": "65",
//         "responseRate": "93%",

//     }

// ];

// const MainRice = () => {
//     return (
//         <div className="main-box">
//             <aside>
//                 <div className="flt-box-wrap">
//                     <div className="flt-box mb-0 flt-head">Filters By</div>
//                     <div className="flt-box bdrt-0">
//                         <p className="flt-title">
//                             Related Categories
//                         </p>
//                         <div className="flt-content">
//                             <ul className="flt-list cust-scroll">
//                                 <li><Link to="#">Onion Seeds</Link></li>
//                                 <li><Link to="#">Vegetable Seeds</Link></li>
//                                 <li><Link to="#">Agricultural Products</Link></li>
//                             </ul>
//                         </div>
//                     </div>
//                     <div className="flt-box">
//                         <p className="flt-title">
//                             By State
//                         </p>
//                         <div className="flt-content">
//                             <div className="flt-search">
//                                 <input type="text" name="state_id" placeholder="Search State" id="state-search-input" />
//                             </div>
//                             <ul className="flt-list cust-scroll" id="state-lists">
//                                 <li><Link to="#">All India</Link></li>
//                                 <li><Link to="#">Maharashtra</Link></li>
//                                 <li><Link to="#">Gujarat</Link></li>
//                                 <li><Link to="#">Tamil Nadu</Link></li>
//                                 <li><Link to="#">Karnataka</Link></li>
//                                 <li><Link to="#">Rajasthan</Link></li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//             </aside>
//             <main>
//                 <section className="section">
//                     <p className="sect-title">Explore by Categories</p>
//                     <div className="horprd expcatg" id="expcatg">

//                         {/* Add more categories related to Onion Seeds */}
//                         <div className="item">
//                             <Link to="#">
//                                 <div className="horprd-box">
//                                     <figure>
//                                         <img src="/assets/freshonion.jpeg" width={55} height={55} alt="Fresh Onion" />
//                                     </figure>
//                                     <p className="title">Fresh Onion</p>
//                                 </div>
//                             </Link>
//                         </div>
//                         {/* Add more categories related to Onion Seeds */}
//                         <div className="item">
//                             <Link to="#">
//                                 <div className="horprd-box">
//                                     <figure>
//                                         <img src="/assets/whiteonion.jpeg" width={55} height={55} alt="White Onion" />
//                                     </figure>
//                                     <p className="title">White Onion</p>                                 </div>
//                             </Link>
//                         </div>
//                         {/* Add more categories related to Onion Seeds */}
//                         <div className="item">
//                             <Link to="#">
//                                 <div className="horprd-box">
//                                     <figure>                                         <img src="/assets/onion1.jpeg" width={55} height={55} alt="Dehydrated Onion" />
//                                     </figure>
//                                     <p className="title">Dehydrated Onion</p>
//                                 </div>
//                             </Link>
//                         </div>
//                         <div className="item">
//                             <Link to="#">
//                                 <div className="horprd-box">
//                                     <figure>
//                                         <img src="/assets/onion2.jpeg" width={55} height={55} alt="Red Onion" />
//                                     </figure>
//                                     <p className="title">Red Onion</p>
//                                 </div>
//                             </Link>
//                         </div>
//                         {/* Add more categories as needed */}
//                     </div>

//                 </section>
//                 <ul className="classfied-wrap">
//                     {classifiedData.map((item, index) => (
//                         <li key={index}>
//                             <div className="classified">
//                                 <div className="prd-info">
//                                     <div className="prd-box">
//                                         <img src={item.imgSrc} alt={item.altText} width={250} height={250} />
//                                     </div>
//                                 </div>
//                                 <div className="cinfo">
//                                     <div className="cbox">
//                                         <figure>
//                                             <span className="cmp-year">{item.years}</span>
//                                         </figure>
//                                         <div className="cboxr">
//                                             <Link to="#" target="_blank"><h4 className="title">{item.companyName}</h4></Link>
//                                             <p className="cloc tooltip ellipsis">
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi-location" viewBox="0 0 16 16">
//                                                     <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
//                                                 </svg>
//                                                 {item.location}
//                                                 <span className="tooltiptext">{item.tooltipText}</span>
//                                             </p>
//                                             <div className="rating-wrap">
//                                                 <span className="rtbox">{item.rating}</span>
//                                                 <span className="crate" style={{ "--_score": item.ratingPercent }} />
//                                                 <span className="rate-text">{item.ratingsCount} Ratings</span>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="caddit">
//                                         <div className="item">
//                                             <div className="ca-box">
//                                                 <p><i className="l3icon resp-icon" /></p>
//                                                 <p><span>Response Rate</span> <b>{item.responseRate}</b></p>
//                                             </div>
//                                             <p><span>Main Products:</span> <b>{item.mainProducts}</b></p>
//                                         </div>
//                                     </div>
//                                     <div className="caction">
//                                         <Link to={'/register-buyer'} >
//                                             <p>Contact Supplier</p>
//                                         </Link>
//                                     </div>
//                                 </div>
//                             </div>
//                         </li>
//                     ))}
//                 </ul>
//             </main>
//         </div>
//     );
// }

// export default MainRice;
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./main-cat.css";

const classifiedData = [
  {
    name: "Mr. Sandesh Durgawale",
    email: "sandeshdurgawale@gmail.com",
    password: "Sandesh@123",
    mobileNumber: "7499228062",
    companyName: "Sandesh Durgawale",
    productOrService: "Onion Seeds",
    imgSrc: "/assets/yellowonion.jpeg",
    altText: "Onion Seeds - Sandesh Durgawale",
    mainProducts: "High-Quality Onion Seeds, Agricultural Products",
    years: "1 YRS",
    location: "Nagpur, India",
    tooltipText: "789 Seed Lane, Agricultural Park, Nagpur, India",
    rating: "4.7",
    ratingPercent: "94%",
    ratingsCount: "85",
    responseRate: "88%",
  },
  {
    _id: "a901234567890abcdef0123", // Random unique ID
    name: "Babubhai",
    email: "babubhai@gmail.com",
    password: "babubhai", // Including the password as specified
    mobileNumber: "9925096366",
    companyName: "Kishan Exim",
    productOrService: "Onion Powder",
    imgSrc: "/assets/onionpowder.jpeg", // Example image path
    mainProducts: "Onion Powder, Dehydrated Onions, Garlic Powder",
    altText: "Onion Powder - Kishan Exim",
    years: "1 YRS",
    location: "Gujarat, India",
    tooltipText: "123 Agro Street, Gujarat, India",
    rating: "4.4",
    ratingPercent: "89%",
    ratingsCount: "65",
    responseRate: "93%",
  },
  {
    _id: "f1234567890abc5678",
    name: "Sarvesh Rajput",
    email: "ramsingchungde4@gmail.com",
    password: "sarveshrajput",
    mobileNumber: "9325703002",
    companyName: "Sarvesh Agro",
    productOrService: "Onion Seeds",
    imgSrc: "/assets/onion3.jpeg",
    mainProducts: "Onion Seeds, Agricultural Seeds, Fertilizers, onions",
    altText: "Sarvesh Agro - Onion Seeds",
    years: "1 YRS",
    location: "Uttar Pradesh, India",
    tooltipText: "Sarvesh Agro, Uttar Pradesh",
    rating: "4.3",
    ratingPercent: "86%",
    ratingsCount: "60",
    responseRate: "83%",
    whatsappConfirmed: true,
  },
  {
    _id: "shani-jyot-agro-maharastra",
    name: "Sanjay Dhakane",
    email: "sanjaydhakane@gmail.com",
    password: "12345678",
    mobileNumber: "9960098708",
    companyName: "Shani Jyot Agro Farmer Producer Company Private Limited",
    productOrService: "Onion",
    imgSrc: "/assets/onion4.jpg",
    mainProducts: "Onions, Agricultural Products",
    altText: "Shani Jyot Agro - Onion",
    years: "1 YRS",
    location: "Maharashtra, India",
    tooltipText: "Shani Jyot Agro Farmer Producer Company Pvt Ltd, Maharashtra",
    rating: "4.2",
    ratingPercent: "84%",
    ratingsCount: "60",
    responseRate: "78%",
    whatsappConfirmed: false,
  },
  {
    _id: "wamantara-farmer-producer-yeola",
    name: "Bapu Gaikwad",
    email: "bapugaikwad7818@gmail.com",
    password: "Bapu Gaikwad",
    mobileNumber: "9623927818",
    companyName: "Wamantara Farmer Producer Company Ltd",
    productOrService: "Maize, Onion",
    imgSrc: "/assets/onion6.jpg",
    mainProducts: "Maize, Onion",
    altText: "Wamantara Farmer Producer - Maize, Onion",
    years: "1 YRS",
    location: "Yeola, Maharashtra, India",
    tooltipText: "Wamantara Farmer Producer, Maize, Onion, Yeola",
    rating: "4.7",
    ratingPercent: "93%",
    ratingsCount: "32",
    responseRate: "90%",
    whatsappConfirmed: true,
  },
  {
    _id: "ebrahim-sayyad-pune",
    name: "Ebrahim Sayyad",
    email: "EbrahimSayyad123@gmail.com",
    password: "Ebrahim Sayyad",
    mobileNumber: "7056414670",
    companyName: "Brother Enterprises",
    statename: "Mumbai",
    cityname: "Pune",
    productOrService: "Onion",
    imgSrc: "/assets/onion12.jpg",
    mainProducts: "Fresh Onions, Export Quality Onions",
    altText: "Brother Enterprises - Leading Onion Supplier in Pune",
    years: "1 YRS",
    location: "Pune, Mumbai, India",
    tooltipText: "Brother Enterprises - Trusted Onion Supplier",
    rating: "4.5",
    ratingPercent: "89%",
    ratingsCount: "120",
    responseRate: "85%",
    whatsappConfirmed: false,
  },
  {
    _id: "mr-jugraj-singh-jugrajj-foods-and-herbs-punjab-sardulgarh",
    name: "Mr, Jugraj Singh",
    email: "Jugrajvirk@gmail.com",
    password: "Mr, Jugraj Singh",
    mobileNumber: "9815566104",
    companyName: "Jugrajj Foods and Herbs",
    statename: "Punjab",
    cityname: "Sardulgarh",
    productOrService: "Banana Powder, Onion Powder, Herbs",
    imgSrc: "/assets/onionpo.jpg",
    mainProducts: "Onion Powder, Herbs",
    altText: "Jugrajj Foods and Herbs - Sardulgarh",
    years: "2 YRS",
    location: "Sardulgarh, Punjab",
    tooltipText: "Jugrajj Foods and Herbs, Sardulgarh",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "112",
    responseRate: "92%",
    whatsappConfirmed: false,
  },

  {
    _id: "kings-dehydrated-foods-private-limited-bhavnagar",
    name: "Mr. M.Reza H Vakil",
    email: "info@kings-dehydrated-onions.com",
    password: "12345678",
    mobileNumber: "9227565003",
    companyName: "Kings Dehydrated Foods Private Limited",
    statename: "Mahuva",
    cityname: "Bhavnagar",
    productOrService: "Dehydrated Onion",
    imgSrc: "/assets/onion8.jpg",
    mainProducts: "Dehydrated Onion",
    altText: "Kings Dehydrated Foods - Trusted Dehydrated Onion Supplier",
    years: "1 YRS",
    location: "Bhavnagar, Mahuva",
    tooltipText:
      "Kings Dehydrated Foods - Quality Dehydrated Onion Manufacturer",
    rating: "4.8",
    ratingPercent: "96%",
    ratingsCount: "200",
    responseRate: "90%",
    whatsappConfirmed: true,
  },
  {
    _id: "chhatariya-foods-pvt-ltd-pune",
    name: "Nitin Joshi",
    email: "info@chhatariyafoods.com",
    password: "nitin joshi",
    mobileNumber: "7203030346",
    companyName: "Chhatariya Foods Pvt. Ltd.",
    statename: "Maharashtra",
    cityname: "Pune",
    productOrService: "Dehydrated Products, Onion Powder",
    imgSrc: "/assets/onion18.jpg",
    mainProducts: "Dehydrated Products, Onion Powder",
    altText: "Chhatariya Foods - Leading Dehydrated Products Supplier",
    years: "1 YRS",
    location: "Pune, Maharashtra",
    tooltipText:
      "Chhatariya Foods - Trusted Source for Dehydrated Products and Onion Powder",
    rating: "4.7",
    ratingPercent: "94%",
    ratingsCount: "150",
    responseRate: "88%",
    whatsappConfirmed: true,
  },
  {
    _id: "s345678901abcdef234580", // Random unique ID
    name: "Lomesh",
    email: "lomesh123@gmail.com",
    password: "lomesh1234",
    mobileNumber: "8624969851",
    companyName: "Jiaa Traders",
    productOrService: "Onion",
    imgSrc: "/assets/onion19.jpg", // Example image path
    mainProducts: "Fresh Onions, Onion Powder",
    altText: "Onion Supplier - Jiaa Traders",
    years: "1 YRS", // Replace X with the actual years of experience
    location: "Nashik, Maharashtra, India",
    tooltipText: "Nashik, Maharashtra, India",
    rating: "4.4",
    ratingPercent: "88%",
    ratingsCount: "40",
    responseRate: "87%",
    whatsappConfirmed: true,
  },
  {
    _id: "brm_export",
    name: "Vinay Ramesh More",
    email: "brmexports@gmail.com",
    password: "Vinay Ramesh More",
    mobileNumber: "7821806326",
    companyName: "BRM Export",
    statename: "Maharashtra",
    cityname: "Pune",
    productOrService: "Onion Powder",
    imgSrc: "/assets/onion-powder.jpg",
    mainProducts: "Onion Powder",
    altText: "BRM Export - Supplier of Onion Powder",
    years: "1 YRS",
    location: "Pune, Maharashtra, India",
    tooltipText: "Vinay Ramesh More - Trusted Supplier of Onion Powder",
    rating: "4.6",
    ratingPercent: "91%",
    ratingsCount: "87",
    responseRate: "89%",
    whatsappConfirmed: true,
  },
];

const MainRice = () => {
  return (
    <>
      <div className="main-box">
        <Helmet>
          <title>
            contact the best red onion exporters & also get the pure Indian
            spices at globalb2bmart
          </title>
          <meta
            name="description"
            content="Connect with the best suppliers of fresh red onion & pure Indian spices at globalb2bmart. we are best b2b portal in India, connecting buyers and suppliers."
          />
        </Helmet>
        <aside>
          <div className="flt-box-wrap">
            <div className="flt-box mb-0 flt-head">Filters By</div>
            <div className="flt-box bdrt-0">
              <p className="flt-title">Related Categories</p>
              <div className="flt-content">
                <ul className="flt-list cust-scroll">
                  <li>
                    <Link to="#">Onion Seeds</Link>
                  </li>
                  <li>
                    <Link to="#">Vegetable Seeds</Link>
                  </li>
                  <li>
                    <Link to="#">Agricultural Products</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flt-box">
              <p className="flt-title">By State</p>
              <div className="flt-content">
                <div className="flt-search">
                  <input
                    type="text"
                    name="state_id"
                    placeholder="Search State"
                    id="state-search-input"
                  />
                </div>
                <ul className="flt-list cust-scroll" id="state-lists">
                  <li>
                    <Link to="#">All India</Link>
                  </li>
                  <li>
                    <Link to="#">Maharashtra</Link>
                  </li>
                  <li>
                    <Link to="#">Gujarat</Link>
                  </li>
                  <li>
                    <Link to="#">Tamil Nadu</Link>
                  </li>
                  <li>
                    <Link to="#">Karnataka</Link>
                  </li>
                  <li>
                    <Link to="#">Rajasthan</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </aside>
        <main>
          <section className="section">
            <p className="sect-title">Explore by Categories</p>
            <div className="horprd expcatg" id="expcatg">
              {/* Add more categories related to Onion Seeds */}
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/freshonion.jpeg"
                        width={55}
                        height={55}
                        alt="Fresh Onion"
                      />
                    </figure>
                    <p className="title">Fresh Onion</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/whiteonion.jpeg"
                        width={55}
                        height={55}
                        alt="White Onion"
                      />
                    </figure>
                    <p className="title">White Onion</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/onion1.jpeg"
                        width={55}
                        height={55}
                        alt="Dehydrated Onion"
                      />
                    </figure>
                    <p className="title">Dehydrated Onion</p>
                  </div>
                </Link>
              </div>
              <div className="item">
                <Link to="#">
                  <div className="horprd-box">
                    <figure>
                      <img
                        src="/assets/onion2.jpeg"
                        width={55}
                        height={55}
                        alt="Red Onion"
                      />
                    </figure>
                    <p className="title">Red Onion</p>
                  </div>
                </Link>
              </div>
              {/* Add more categories as needed */}
            </div>
          </section>
          <ul className="classfied-wrap">
            {classifiedData.map((item, index) => (
              <li key={index}>
                <div className="classified">
                  <div className="prd-info">
                    <div className="prd-box">
                      <img
                        src={item.imgSrc}
                        alt={item.altText}
                        width={250}
                        height={250}
                      />
                    </div>
                  </div>
                  <div className="cinfo">
                    <div className="cbox">
                      <figure>
                        <span className="cmp-year">{item.years}</span>
                      </figure>
                      <div className="cboxr">
                        <Link to="#" target="_blank">
                          <h4 className="title">{item.companyName}</h4>
                        </Link>
                        <p className="cloc tooltip ellipsis">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi-location"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                          </svg>
                          {item.location}
                          <span className="tooltiptext">
                            {item.tooltipText}
                          </span>
                        </p>
                        <div className="rating-wrap">
                          <span className="rtbox">{item.rating}</span>
                          <span
                            className="crate"
                            style={{ "--_score": item.ratingPercent }}
                          />
                          <span className="rate-text">
                            {item.ratingsCount} Ratings
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="caddit">
                      <div className="item">
                        <div className="ca-box">
                          <p>
                            <i className="l3icon resp-icon" />
                          </p>
                          <p>
                            <span>Response Rate</span>{" "}
                            <b>{item.responseRate}</b>
                          </p>
                        </div>
                        <p>
                          <span>Main Products:</span> <b>{item.mainProducts}</b>
                        </p>
                      </div>
                    </div>
                    <div className="caction">
                      <Link to={"/register-buyer"}>
                        <p>Contact Supplier</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </main>
      </div>
      {/* <div class="onion-container">
        <h1 style={{ color: "green" }} class="onion-title">
          Red Onion Exporters: Globalb2bmart offers you the best
        </h1>
        <p class="onion-description">
          GlobalB2Bmart takes pride in being the top B2B portal in India by
          bridging the gap between the buyer and the trusted suppliers across a
          wide range of categories, from clothing to chemicals, furniture, and
          most prominently, agricultural products. Among our specialized offers,
          we connect businesses with reliable red onion exporters, ensuring
          top-quality produce for both domestic and international markets.
        </p>
        <h2 class="onion-heading">
          Red Onion Exporters: Quality You Can Trust
        </h2>
        <p class="onion-info">
          Quality and reliability mark the aspect of red onion exporters.
          GlobalB2Bmart connects the buyer to its suppliers of fresh,
          high-quality red onions. The onions feature a rich flavor and robust
          taste as well as being nutritious enough to be consumed in many
          applications in the cooking world. We ensure that when you look to
          source local markets or export, our suppliers meet the standard of
          packaging, freshness, and delivery to make it into any country of the
          globe.
        </p>
        <div class="onion-quality-section">
          <h2 style={{ padding: "30px 0px" }} class="onion-heading">
            Fresh Red Onion—Right from the Fields
          </h2>
          <p class="onion-info">
            One of the largest producers of onions in the world is India, and
            one of the most in-demand fresh agricultural products is red onion.
            In GlobalB2Bmart, we provide buyers with direct access to the
            freshest red onions available, as harvested from reliable farmers
            and suppliers. Handpicked, these onions retain their natural taste
            and nutrients, thus best for kitchens across the globe that demand
            quality and freshness.
          </p>
        </div>

        <div class="onion-dehydrated-section">
          <h2 class="onion-heading">
            Dehydrated Onion: Versatility in Every Granule
          </h2>
          <p class="onion-info">
            GlobalB2Bmart also provides fresh onions besides offering
            top-quality dehydrated onion suppliers. Dehydrated onions are
            long-lasting, convenient substitutes for fresh onions. They can be
            used in instant food products, spice blends, and ready-to-eat meals.
            Buyers can find here consistent quality suppliers of dehydrated
            onions who have processed the products under hygienic conditions
            that preserve the flavor and nutritional value of fresh onions.
          </p>
        </div>

        <div class="onion-white-section">
          <h2 class="onion-heading">
            White Onion: Mild, Sweet, and Perfect for Culinary Creations
          </h2>
          <p class="onion-info">
            For buyers seeking a more delicate flavor, GlobalB2Bmart offers
            connections with trusted suppliers of white onion. These onions are
            prized for their mild taste and are commonly used in gourmet dishes,
            salads, and pickles. Our suppliers ensure that white onions meet
            international quality standards, making them a preferred choice for
            both domestic and export markets.
          </p>
        </div>

        <div class="onion-spices-section">
          <h2 class="onion-heading">
            Pure Indian Spices: The Flavor of Tradition
          </h2>
          <p class="onion-info">
            In addition to onions, GlobalB2Bmart is proud to connect buyers with
            pure Indian spices. Indian spices, world-renowned for their
            aromatics and flavors, form the backbone of most cuisines. Be it
            turmeric, cumin, coriander, or chili powder, we ensure our suppliers
            provide authentic, high-grade spices meeting international quality
            standards. Buyers can search for a variety of spices from the best
            farms in India.
          </p>
        </div>

        <div class="onion-why-choose-section">
          <h2 class="onion-heading">Why GlobalB2Bmart?</h2>
          <ul class="onion-list">
            <li class="onion-list-item">
              Verified Suppliers: We guarantee that all suppliers on our site
              are vetted for quality and reliability.
            </li>
            <li class="onion-list-item">
              Diverse Categories: from onions and spices to chemicals and
              furniture, we are covering a wide range of industries.
            </li>
            <li class="onion-list-item">
              Digital Marketing Services: We promote our clients online with
              professional services of SEO, SMO, and Google My Business
              listings.
            </li>
            <li class="onion-list-item">
              Digital Catalogs: We create professional digital catalogs for the
              sellers as well, depicting their products effectively on our
              portal.
            </li>
            <li class="onion-list-item">
              Global Reach: Our focus is always on export-quality goods.
              Therefore, we try to bring Indian suppliers face-to-face with
              buyers across the globe.
            </li>
          </ul>
        </div>
        <div class="onion-support-container">
          <h1 class="onion-support-title">
            How We Support Red Onion Exporters and Buyers
          </h1>
          <p class="onion-support-description">
            GlobalB2Bmart offers a smooth platform for red onion exporters to
            showcase their products to a global audience. Our digital catalogs
            and business listings ensure maximum visibility, while our
            platform's user-friendly design makes it easy for buyers to connect
            with suppliers.
          </p>

          <div class="onion-fresh-produce-section">
            <h2 class="onion-support-heading">
              Fresh Produce, Verified Suppliers
            </h2>
            <p class="onion-support-info">
              At GlobalB2Bmart, we ensure that every product listed, from fresh
              red onions to dehydrated onions and spices, is sourced from
              reliable suppliers who meet stringent quality standards.
            </p>
          </div>

          <div class="onion-digital-marketing-section">
            <h2 class="onion-support-heading">
              Increased Online Presence through Digital Marketing
            </h2>
            <p class="onion-support-info">
              Our SEO and SMO expertise will help suppliers increase their
              presence online, attract more customers, and increase sales. Our
              team is also optimizing Google My Business listings to drive local
              and worldwide traffic.
            </p>
          </div>

          <div class="onion-international-trading-section">
            <h2 class="onion-support-heading">Easy International Trading</h2>
            <p class="onion-support-info">
              GlobalB2Bmart focuses on export-quality products, which reduces
              the complexity of international trade and allows Indian suppliers
              to take their business to the whole world.
            </p>
          </div>

          <div class="onion-quality-ensurance-section">
            <h2 class="onion-support-heading">Quality Ensurance</h2>
            <p class="onion-support-info">
              We value your trust, quality, and reliance at GlobalB2Bmart.
              Whether you're sourcing for red onions, white onions, or pure
              Indian spices, we ensure to connect you to the relevant suppliers
              who adhere to such excellence.
            </p>
          </div>
        </div>
        <div class="onion-contact-section">
          <h2 class="onion-heading">Contact Us Now</h2>
          <p class="onion-info">
            Are you ready to source the best agroproducts or enhance visibility
            for your business? Contact us at GlobalB2Bmart now!
          </p>
        </div>
      </div> */}
      <div class="category-content">
        <h2
          style={{
            color: "orange",
            textAlign: "center",
            margin: "20px 0px",
          }}
        >
          Red Onion Exporters | Fresh Red Onion | GlobalB2BMart
        </h2>
        <p class="section-heading">
          <strong>Red Onion Exporters: Quality You Can Trust</strong>
        </p>
        <p class="section-content">
          GlobalB2BMart is your portal that leads you to the right red onion
          exporters who will ensure the best product in both domestic and
          international markets. Here, buyers can connect with credible
          suppliers, ensuring them that they get the freshest, highest-quality
          red onions of global standards. These onions are rich in flavor,
          texture, and nutrients. They are an essential item in nearly all
          kitchens around the world. Our wide networks of exporters ensure
          premium packing, easy deliveries, and high-quality control, which
          helps customers order red onions with confidence.
        </p>

        <p class="section-heading">
          <strong>Fresh Red Onion—Right from the Fields</strong>
        </p>
        <p class="section-content">
          <b> Fresh red onion</b> is the most demanded item of agriculture-based
          products worldwide, and India is on the list to be one of the top
          agricultural producers. This website, GlobalB2BMart, is a proud place
          for the direct source buyer because we sell products through the
          finest authentic farmers and the suppliers of these fresh red onion
          products. Only the pick of the pick can be made to retain its normal
          taste, nutritive value, and freshness. Fresh red onions bring in the
          best flavors and texture into salads, curries, or simple traditional
          recipes. All our suppliers follow hygiene qualities. Which ensures
          that all products are supplied appropriately as per international food
          safety regulations.
        </p>
        <p class="section-heading">
          <strong>Dehydrated Onion: Versatility in Every Granule</strong>
        </p>
        <p class="section-content">
          Apart from fresh produce, GlobalB2BMart is also known for connecting
          buyers and suppliers of dehydrated onions. Dehydrated onions can be a
          better alternative to using fresh onions. They have a longer shelf
          life and are easy to apply to different food requirements. Dehydrated
          onions are perfect for instant food products, spice blends, soups, and
          ready-to-eat meals. They retain the flavor of fresh onions without any
          storage problems. Our suppliers ensure that the dehydration process
          maintains the flavor, aroma, and nutritional content, making them a
          preferred choice for global food manufacturers.
        </p>

        <p class="section-heading">
          <strong>
            White Onion: Mild, Sweet, and Perfect for Culinary Creations
          </strong>
        </p>
        <p class="section-content">
          If one is in the market for a less dynamic onion variety, white onion
          access to the best suppliers is possible with GlobalB2BMart. White
          onions are famous for their subtle sweetness and delicate flavor. They
          are easy in the preparation of salads and pickles and offer a crunchy
          texture along with sweet flavors that never dominate the overall
          flavors of other foods. Our manufacturers ensure that our white onions
          are of the best quality, in compliance with international export
          standards, thus providing uniformity in size, freshness, and taste.
          Whether it is for domestic or international consumption, GlobalB2BMart
          assures that only the best quality white onions in the market are
          supplied to the buyers.
        </p>
        <p class="section-heading">
          <strong>Why GlobalB2BMart for Red Onion Exporters?</strong>
        </p>
        <p class="section-content">
          One of the highest B2B marketplaces in India, GlobalB2BMart offers
          professional trade links both to the buyers and genuine red onion
          exporters. That is why businesses prefer our platform:
          <b>Reliable Suppliers:</b> We deal with certified export firms that
          guarantee the authenticity and quality of the products.
          <b>Superior Quality:</b> Our suppliers follow more demanding agro-food
          safety practices, which means excellent quality red onions.
          <b>Competitive Pricing:</b> We get directly from farms, so the buyers
          need not take the second best to get the fresh ones without unwanted
          markups.
          <b>Global Reach:</b> Whether it's in local markets or across borders
          for export, our efficient distribution and coordinated support are
          given.
          <b>Comprehensive Product Range:</b> We cover all market demands,
          starting from fresh red onions to dehydrated and white onions.
        </p>
        <p class="section-heading">
          <strong>
            Sourcing and Packaging Standards of Red Onion Exporters
          </strong>
        </p>
        <p class="section-content">
          Our red onion exporters ensure fresh products that last for longer
          periods of time because of a quality check. Packing has also become
          highly significant during the transit procedure. GlobalB2BMart
          suppliers use mesh bags for: Proper ventilation to check for spoilage
          Carton boxes for shipment of large quantities in good condition
          Customized packing according to client specifications and in case of
          bulk orders, there is a retail market as well
        </p>
        <p class="section-heading">
          <strong>Export Fresh Red Onions to Meet Global Requirements</strong>
        </p>
        <p class="section-content">
          The demand for fresh red onions in the global market and the
          requirement of the supply end are rather filled by GlobalB2Bart. Here,
          our exporters maintain international quality certifications and
          standards so that every shipment reaches the buyer's country with the
          highest food safety requirements satisfied. We ensure you that whether
          you are a bulk buyer who requires supply or have specific regions to
          ship to, our platform will give you smooth trading solutions with
          fair, transparent price calls and assured shipping options.
        </p>
        <p class="section-heading">
          <strong>
            Partner with GlobalB2BMart—Your Gateway to Quality Agricultural
            Products
          </strong>
        </p>
        <p class="section-content">
          The Globalb2bmart is more than a B2B marketplace; it is a partner in
          sourcing prime, best agricultural products. Direct contacts with the
          largest red onion suppliers guarantee that all your transactions will
          be efficient, reliable, and affordable. If you need fresh red onions,
          dehydrated onions, or white onions, sourcing on this platform is
          always seamless. Be our partner now and take your business to greater
          heights with the best product available in the market.
        </p>
        <p class="section-heading">
          <strong>About GlobalB2BMart</strong>
        </p>
        <p class="section-content">
          GlobalB2BMart is the B2B portal of India, and we focus on bringing
          buyers and verified suppliers from various categories under one roof.
          From red onions and agricultural products to chemicals, furniture, and
          textiles, we are bringing their business directly to quality-oriented
          suppliers. Within trust, efficiency, and smoothness in transactions,
          our portal provides easy and reliable global trade. If you are either
          a buyer or a seller, then Globalb2bmart is the greatest destination
          for a smooth, successful business collaboration.
        </p>
      </div>
    </>
  );
};

export default MainRice;
