import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../FreeCataloges.jsx/FreeCataloge.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGauge,
  faShieldHalved,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const products = [
  { id: 1, name: "Bed", image: "./assets/bed1.jpg" },
  { id: 3, name: "Sofa", image: "./assets/sofa1.jpeg" },
  { id: 5, name: "Chair", image: "./assets/chair.jpeg" },
  { id: 6, name: "Furniture", image: "./assets/furniture18.jpg" },
  { id: 1, name: "Plastic Furniture", image: "./assets/plastic-fur.jpg" },
  { id: 3, name: "Toilet Paper Holder", image: "./assets/plastic6.jpg" },
  { id: 5, name: "Plastic Tables", image: "./assets/plastic1.jpg" },
  { id: 6, name: "Plastic Chairs", image: "./assets/plastic2.jpg" },
];

const Safexnic = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [highlight, setHighlight] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleReachEnd = () => {
    if (swiperInstance) {
      const isEnd = swiperInstance.isEnd;
      const navigationButton = document.querySelector(".swiper-button-next");
      if (navigationButton) {
        navigationButton.style.display = isEnd ? "none" : "block";
      }
    }
  };

  const handleReachStart = () => {
    if (swiperInstance) {
      const isBeginning = swiperInstance.isBeginning;
      const navigationButton = document.querySelector(".swiper-button-prev");
      if (navigationButton) {
        navigationButton.style.display = isBeginning ? "none" : "block";
      }
    }
  };

  const handleProductClick = () => {
    setActiveSection("products");
  };

  const renderContent = () => {
    switch (activeSection) {
      case "products":
        return (
          <div className="swiper-container">
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              slidesPerView={1}
              spaceBetween={10}
              pagination={{ clickable: true }}
              navigation={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              onSwiper={setSwiperInstance}
              onReachEnd={handleReachEnd}
              onReachBeginning={handleReachStart}
              breakpoints={{
                640: { slidesPerView: 2, spaceBetween: 20 },
                768: { slidesPerView: 4, spaceBetween: 40 },
                1024: { slidesPerView: 7, spaceBetween: 30 },
              }}
              className="mySwiper"
            >
              {products.map((product) => (
                <SwiperSlide key={`product-${product.id}`}>
                  <div className="card" onClick={handleProductClick}>
                    <img src={product.image} alt={product.name} />
                    <p>{product.name}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        );

      default:
        return (
          <div>
            <div className="swiper-container">
              <Swiper
                modules={[Autoplay, Navigation, Pagination]}
                slidesPerView={1}
                spaceBetween={10}
                pagination={{ clickable: true }}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                onSwiper={setSwiperInstance}
                onReachEnd={handleReachEnd}
                onReachBeginning={handleReachStart}
                breakpoints={{
                  640: { slidesPerView: 2, spaceBetween: 20 },
                  768: { slidesPerView: 4, spaceBetween: 40 },
                  1024: { slidesPerView: 7, spaceBetween: 30 },
                }}
                className="mySwiper"
              >
                {products.map((product) => (
                  <SwiperSlide key={`product-${product.id}`}>
                    <div className="card">
                      <img src={product.image} alt={product.name} />
                      <p>{product.name}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="welcome">
              <h1>Welcome to the SAFEX INC Company</h1>
              <p>
                Welcome to SAFEX INC, your trusted partner in the world of
                furniture solutions. We are a company committed to offering
                high-quality, innovative, and stylish furniture for a variety of
                spaces, catering to both residential and commercial needs. With
                years of experience in the furniture industry, we have earned a
                reputation for delivering exceptional products that combine
                functionality with aesthetic appeal.
              </p>
              <h2>We Deals in All kind of Furniture</h2>
              <p>
                SAFEX INC, we understand that furniture is more than just a
                practical need; it plays a vital role in shaping the ambiance
                and functionality of any space. Whether you are furnishing your
                home, office, or commercial establishment, we offer a
                comprehensive range of furniture options that suit diverse
                tastes and requirements. Our collections include everything from
                luxurious sofas, stylish tables, and ergonomic office chairs to
                custom-made furniture that reflects your unique style. Each
                piece is designed to enhance the comfort and elegance of your
                environment while serving its intended purpose with durability.
              </p>
              {/* <h2>Our Products :</h2> */}
              <p>
                Our diverse portfolio is carefully curated to meet the needs of
                different sectors. For residential spaces, we provide an
                assortment of modern, traditional, and contemporary furniture
                that will elevate the look and feel of your living room,
                bedroom, dining area, and more. From plush sofas and functional
                coffee tables to sturdy beds and sleek storage solutions, our
                home furniture is crafted to cater to all your design
                preferences.
              </p>
              <p>
                For businesses, we offer practical and professional furniture
                designed to promote productivity and comfort in the workplace.
                Our office furniture collection includes ergonomic chairs,
                modular desks, conference tables, storage units, and much more.
                We understand the importance of creating an efficient and
                pleasant work environment, and our furniture solutions are
                tailored to help your team work in comfort while leaving a
                lasting impression on clients and visitors. In addition to
                residential and office furniture, SAFEX INC also specializes in
                providing custom-made furniture solutions for commercial
                establishments, including hotels, restaurants, cafes, and retail
                stores. We know that each business has unique needs, and our
                bespoke designs ensure that your furniture not only fits
                perfectly into your space but also enhances your brand identity.
                Our team works closely with clients to understand their vision
                and deliver furniture that meets both functional and aesthetic
                expectations. One of the core values of SAFEX INC is our
                dedication to quality. We source materials from trusted
                suppliers, ensuring that every piece of furniture we create is
                crafted to last. Our skilled craftsmen, designers, and engineers
                work tirelessly to produce furniture that is not only visually
                appealing but also built for longevity. Each product undergoes
                rigorous quality checks to ensure that it meets the highest
                industry standards. Furthermore, we understand the importance of
                sustainability in today’s world. At SAFEX INC, we take pride in
                offering eco-friendly furniture options made from sustainable
                materials. We aim to minimize our environmental footprint while
                still delivering high-quality products that are safe for your
                home and the planet.
              </p>
              {/* <p>Unmatched Expertise
                                With years of experience in the elevator industry, our skilled team brings unparalleled knowledge and proficiency to every project.</p> */}

              <li>
                <div className="pro_inner">
                  <div className="pro_img_sec">
                    <img
                      src="./assets/sofa1.jpeg"
                      title="Kashmiri Saffron Threads"
                      alt="Kashmiri Saffron Threads"
                      width="202"
                      height="299"
                      decoding="async"
                    />
                  </div>
                  <div className="products_info">
                    <div className="pro_title">
                      <h3>Modern Wooden Coffee Table</h3>
                    </div>
                    <div className="pro_det_sec">
                      <ul className="attributes">
                        <li>
                          <span>Brand:</span> SAFEX INC
                        </li>
                        <li>
                          <span>Product:</span> Modern Wooden Coffee Table
                        </li>
                        <li>
                          <span>Material:</span> Made from high-quality,
                          sustainably sourced wood, designed for durability and
                          a classic finish.
                        </li>
                        <li>
                          <span>Shape:</span> Rectangular - Provides ample
                          surface space for living room essentials like books,
                          drinks, and décor.
                        </li>
                        <li>
                          <span>Size:</span> 48 inches (L) x 24 inches (W) x 18
                          inches (H) - Perfect for most living room settings.
                        </li>
                        <li>
                          <span>Number of Shelves:</span> 1 - Features a lower
                          storage shelf for additional storage or décor.
                        </li>
                        <li>
                          <span>Color:</span> Rich walnut finish - Complements a
                          wide range of interior design styles from modern to
                          traditional.
                        </li>
                        <li>
                          <span>Assembly Required:</span> Yes - Comes with
                          easy-to-follow instructions for simple and quick
                          assembly.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div className="company_header">
        <div className="top_head">
          <div className="company_left zdasds">
            <span className="logo_img2" style={{ background: "#d1d786" }}>
              SI
            </span>

            <div className="company_info">
              <div className="company_name2">
                <h1>
                  SAFEX INC{" "}
                  <div className="_oth_info">
                    <a
                      href="https://www.google.co.in/maps/dir//12.9715987,77.5945627"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        <b className="fa fa-map-marker" />
                        Delhi, India{" "}
                      </span>
                    </a>
                  </div>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_head">
          <div className={`Premium-Header ${highlight ? "highlight" : ""}`}>
            <ul className="menu_sec">
              <li>
                Home <FontAwesomeIcon icon={faGauge} />
              </li>
              <li>
                Products <FontAwesomeIcon icon={faShieldHalved} />
              </li>
              <li>
                About Us <FontAwesomeIcon icon={faMessage} />
                <ul className="dropdown"></ul>
              </li>
              <li>
                Contact Us <FontAwesomeIcon icon={faShieldHalved} />
                <ul className="dropdown"></ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {renderContent()}
    </>
  );
};

export default Safexnic;
