import React from "react";
import { Link } from "react-router-dom";
import "./blog.css";

// Import images

const SixthBlogs = () => {
  return (
    <>
      <div
        style={{ fontStyle: "italic", lineHeight: "1.4" }}
        className="blog-container"
      >
        <h1 style={{ textAlign: "center" }} className="blog-title">
          Industrial Grade Chemicals | GlobalB2BMart.
        </h1>

        <div
          className="section"
          style={{ textAlign: "center" }}
          id="onion-seeds"
        >
          <Link to="/">
            <img
              src="/assets/chemicalBlog1.jpg"
              alt="cleaning chemicals"
              className="section-image"
            />
          </Link>
          <h2 className="section-title">Industrial Grade Chemicals</h2>
          <p className="section-content" style={{ margin: "8px 0px" }}>
            {" "}
            <span className="onion-link">
              <Link to="/">
                {" "}
                <b className="onion-link">Cleaning chemicals </b>
              </Link>
            </span>
            Our industrial Grade chemicals are designed to meet the highest
            business standards. These chemicals play an important role in the
            manufacture, preparation, and production processes in various
            sectors. We make it easier to get the same chemicals from a reliable
            supplier so that companies can get the chemicals that are safe,
            capable, and reliable. Our comprehensive industrial chemical catalog
            will be your favorite shop for all your start needs, from natural
            substances to complete goods.
          </p>
        </div>

        <div
          className="section"
          style={{ textAlign: "center" }}
          id="onion-powder"
        >
          <Link to="/">
            <img
              width={250}
              height={200}
              src="/assets/chemicalBlog2.jpg"
              alt="Onion Powder"
              className="section-image"
            />
          </Link>
          <h2 className="section-title">All About Chemicals</h2>
          <p style={{ margin: "18px 0px" }}>
            <span className="onion-link">
              <Link to="/">
                {" "}
                <b className="onion-link">Chemical companies</b>
              </Link>
            </span>{" "}
            We offer an extensive platform for companies active in the
            manufacture, circulation, and use of{" "}
            <b className="onion-link">all about chemicals</b>, alongside
            GlobalB2BMart. Our objective shall be to establish a smooth and
            simplified market for chemical and industrial suppliers. With our
            vast network, we offer services to sectors that reach from
            agriculture to pharmaceuticals, supplying them together with
            dedicated chemical and other important chemical products that set
            industry standards.
            <span className="onion-link">
              <Link to="/">
                <b className="onion-link"></b>
              </Link>
            </span>
          </p>
          <p className="section-content" style={{ margin: "8px 0px" }}>
            We're convinced that companies are highly dependent on reliable
            sources of industrial chemicals. Hence, we work relentlessly to
            ensure that the chemicals available on our platform are never alone
            superior, despite the fact that they also come from trusted
            suppliers complying with world standards. GlobalB2BMart has got
            everything for you, whether you are looking for basic chemicals,
            powerful chemicals, or a custom formulation. The chemical sector
            supports other sectors such as the pharmaceutical industry,
            agricultural sector, among many.
          </p>
        </div>

        <div
          className="section"
          style={{ textAlign: "center" }}
          id="dehydrated-onions"
        >
          <Link to="/">
            <img
              src="/assets/chemicalBlog4.jpg"
              alt="Dehydrated Onions"
              className="section-image"
            />
          </Link>
          <h2 className="section-title">Chemical and Industrial </h2>
          <p className="section-content" style={{ margin: "8px 0px" }}>
            <span className="onion-link">
              {/* <Link to="/">
                {" "}
                <b className="onion-link">Industrial chemicals</b>
              </Link> */}
            </span>{" "}
            GlobalB2BMart will be a sure name when it arrives in the chemical
            and industry goods. We propose a huge range of goods that will serve
            a number of sectors, including the automobile, food and beverage,
            textiles, and oil and gas industries. Our platform enables companies
            to discover everything from natural chemicals for development to
            high-end focused chemicals that play an essential role in a
            particular intention. Our market is the right shop for all your
            needs, whether you are looking for chemical and industrial products
            for large industrial production or sector goals.
          </p>
        </div>

        <div
          className="section"
          style={{ textAlign: "center" }}
          id="white-onion"
        >
          <Link to="/">
            <img
              src="/assets/chemicalBlog3.jpg"
              alt="White Onion"
              className="section-image"
            />
          </Link>
          <h2 className="section-title">Specialized Chemicals</h2>
          <p className="section-content" style={{ margin: "8px 0px" }}>
            Focused specialized chemicals play an important role in the success
            of a lot of fields, and we are proud to have such a large amount of
            products at globalb2bmart. Our website connects customers with
            suppliers who offer chemicals altered to meet specific needs, from
            custom formulations to detailed objectives. These chemicals often
            call for expertise in formulation and production, and we assure you
            that our suppliers follow tough quality and safety standards. Our
            platform provides easy access to concentrated chemicals for a wide
            range of uses, including food preservation, pharmaceuticals,
            plastics, and paints. Our platform simplifies your supply chain if
            you are working on a new product development or looking for a steady
            chemical supply for continuous production.
            <span className="onion-link">
              <Link to="/">
                {" "}
                <b className="onion-link">Cleaning and Chemicals</b>
              </Link>
            </span>{" "}
            The cleaning and chemicals are totally linked, particularly in areas
            demanding very tough hygiene and clean criteria similar to food
            production, health care, and polite reception. Besides
            GlobalB2BMart, we offer a complete chemical product range precisely
            designed for cleaning purposes. We offer products that help
            companies maintain high levels of hygiene and safety, from
            industrial cleaning agents to specialized disinfectants. Our
            cleaning and chemical selection is explained for a wide range of
            sectors, ensuring compatibility with various cleaning techniques.
            Our market ensures that you can find the correct equipment to
            perform safe and effective tasks when you need chemicals to clean
            machines, clean the outer layer, or any other specific objective.
          </p>
        </div>

        <div className="section" style={{ textAlign: "center" }} id="red-onion">
          <Link to="/">
            <img
              src="/assets/chemicalBlog6.jpg"
              alt="Red Onion"
              className="section-image"
            />
          </Link>
          <h2 className="section-title">Indian Chemical Industries</h2>
          <p className="section-content" style={{ margin: "8px 0px" }}>
            <span className="onion-link">
              <Link to="/">
                {" "}
                {/* <b className="onion-link">Specialty chemicals</b> */}
              </Link>
            </span>
            The Indian chemical industry has seen massive growth over the past
            few years, and GlobalB2BMart will be your gateway to study the
            current growth industry. India is now a major chemical producer and
            consumer, and companies are looking to penetrate this vast market.
            We provide valuable insight into the Indian chemical industry
            movement, as well as knowledge on development patterns, retail
            opportunities, and the political context. Global buyers can connect
            with Indian chemical suppliers who have a global excellence
            standard, while local buyers gain access to a wide variety of goods
            that meet their needs. The Indian chemical industry protects a wide
            range of products, ranging from petrochemicals, agrochemicals, and
            pharmaceuticals, in order to be a strong chemical. GlobalB2BMart
            plays a key role in expanding the reach of Indian manufacturers and
            helping them to build credible connections abroad by facilitating
            strong organizational associations within the limits of the chemical
            region of India.
          </p>
        </div>

        <div
          className="section"
          style={{ textAlign: "center" }}
          id="health-benefits"
        >
          <Link to="/">
            <img
              src="/assets/chemicalBlog7.jpg"
              alt="Health Benefits"
              className="section-image"
            />
          </Link>
          <h2 className="section-title">Chemical Plantation </h2>
          <p className="section-content" style={{ margin: "8px 0px" }}>
            <span className="onion-link">
              <Link to="/">
                {" "}
                <b className="onion-link"> Chemical plantation</b>
              </Link>
            </span>{" "}
            refers to the use of chemicals in agriculture to increase crop
            growth, increase output, and protect plants from plague and disease.
            We also offer companies in the agricultural sector, together with a
            wide range of chemical solutions suited to chemical plants, at
            GlobalB2BMart. These products include pesticides, fertilizer, and
            agronomic extras, all of which contribute to agricultural
            activities' productivity and flexibility. Our Link connects buyers
            with suppliers of high-quality chemicals for agricultural use,
            ensuring that all products meet strong ecological and safety
            requirements. If you are looking to improve soil quality, control
            plagues, or, alternatively, increase agricultural production, our
            chemical planting solutions provide a range of tools for improving
            agricultural productivity.
          </p>
        </div>

        <div
          className="section"
          style={{ textAlign: "center" }}
          id="storage-tips"
        >
          <Link to="/">
            <img
              src="/assets/chemicalBlog5.jpg"
              alt="Storage Tips"
              className="section-image"
            />
          </Link>
          <h2 className="section-title">Summary </h2>
          <p className="section-content" style={{ margin: "8px 0px" }}>
            {" "}
            <span className="onion-link">
              {/* <Link to="/">
                {" "}
                <b className="onion-link">Chemical equipment </b>
              </Link> */}
            </span>{" "}
            GlobalB2BMart is a virtual market designed to connect industrial
            chemicals and its suppliers. We offer a wide range of specific
            chemicals and other chemical add-on for businesses such as
            manufacturing, planting, pharmaceuticals, and others as a
            contribution. Our platform, alongside a priority for transparency
            and faith, ensures that organizations will be able to identify
            suitable products, even if they are used for everyday industrial
            applications in other fields. GlobalB2BMart is your reliable Partner
            for Standard Chemical Solutions Whether you are looking for cleaning
            and chemical equipment, chemical plants, or information on the
            Indian chemical industry, GlobalB2BMart is your reliable Partner. We
            provide for the global expansion of our companies by creating strong
            links between our suppliers and our customers.
          </p>
        </div>

        <Link to={"/celebrate-artistry"}>
          <button class="next-btn">Next</button>
        </Link>
      </div>
    </>
  );
};

export default SixthBlogs;
